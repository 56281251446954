import * as actionTypes from '../actionTypes/authentication';

const initialState = {
    currentUser: {},
    userType: "",
    isLoginError: false,
    isLoading: false,
    isError: false,
    errorMessage: "",
    isAuthenticated: false,
    userDataLoaded:false
}

const reducer = (state = initialState, action) => {

    if (action.type === actionTypes.AUTH_ATTEMPT) {
        return authenticationAttempted(state)
    } else if (action.type === actionTypes.AUTH_FAILED) {
        return authenticationFailed(state, action)
    } else if (action.type === actionTypes.AUTH_SUCCESS) {
        return authenticationSuccessfull(state, action)
    } else if (action.type === actionTypes.LOGOUT) {
        return logout(state)
    } else if (action.type === actionTypes.USER_LOGIN_SUCCESSFULL) {
        return userLoginSuccesfull(state,action)
    } else if (action.type === actionTypes.USER_LOGIN_FAILED) {
        return userLoginFailed(state,action)
    } else {
        return state;
    }
}

const authenticationAttempted = (state) => {
    return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        isError: false,
        isLoginError: false
    }
}
const authenticationFailed = (state, action) => {
    return {
        ...state,
        isAuthenticated: false,
        errorMessage: action.payload,
        isError: true,
        isLoading: false,
        userDataLoaded:true
    }
}
const authenticationSuccessfull = (state, action) => {
    return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload.currentUser,
        userType: action.payload.userType,
        isLoading: false,
        isError: false,
        userDataLoaded:true
    }
}
const logout = (state) => {
    return {
        ...state,
        isAuthenticated: false,
        currentUser: {},
        isLoading: false
    }
}
const userLoginSuccesfull = (state, action) => {
    return {
        ...state,
        isAuthenticated: true,
        currentUser: action.payload.currentUser,
        userType: action.payload.userType,
        isLoading: false,
        isLoginError: false
    }
}
const userLoginFailed = (state, action) => {
    return {
        ...state,
        isAuthenticated: false,
        isLoginError: true,
        errorMessage: action.payload,
        isLoading: false
    }
}

export default reducer;