const ONLINE_INTERVIEWS = "/online-interviews";
export const LOGIN_ROUTE = "/auth/login";
export const CANDIDATES = ONLINE_INTERVIEWS + "/candidate";
export const CANDIDATES_BY_USER_ID = ONLINE_INTERVIEWS + "/candidate/paged";
export const CANDIDATES_BY_EMAIL_LIKE = ONLINE_INTERVIEWS + "/candidate/filter?email="
export const LANGUAGES = ONLINE_INTERVIEWS + "/languages";
export const PLAYGROUNDS = ONLINE_INTERVIEWS+"/playgrounds"
export const USER_INVITED_PLAYGROUNDS = ONLINE_INTERVIEWS+"/playgrounds/principal/{userId}/shared-playgrounds"
export const USER_OWNED_PLAYGROUNDS = ONLINE_INTERVIEWS + "/playgrounds/principal/{userId}/created-playgrounds"
export const PLAYGROUND_STATUS = ONLINE_INTERVIEWS + "/playgrounds/statuses"
export const USERS = "/users"
export const QUESTION_ROUTE = ONLINE_INTERVIEWS + "/questions";