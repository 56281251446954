export const BEGIN_PLAYGROUND_ROOM_REQUEST = "BEGIN_PLAYGROUND_ROOM_REQUEST";
export const PLAYGROUND_ROOM_CONNECT_SUCCESSFULL = "PLAYGROUND_ROOM_CONNECT_SUCCESSFULL";
export const PLAYGROUND_ROOM_FETCH_INITIAL_DATA_SUCCESSFULL = "PLAYGROUND_ROOM_FETCH_INITIAL_DATA_SUCCESSFULL";
export const PLAYGROUND_ROOM_CODE_EDITOR_CONTENT_RECEIVED = "PLAYGROUND_ROOM_CODE_EDITOR_CONTENT_RECEIVED";
export const PLAYGROUND_ROOM_LANGUAGE_CHANGE_RECEIVED = "PLAYGROUND_ROOM_LANGUAGE_CHANGE_RECEIVED";
export const PLAYGROUND_ROOM_EXEC_OUTPUT_RECEIVED = "PLAYGROUND_ROOM_EXEC_OUTPUT_RECEIVED";
export const PLAYGROUND_ROOM_EXEC_OUTPUT_REQUESTED = "PLAYGROUND_ROOM_EXEC_OUTPUT_REQUESTED";
export const PLAYGROUND_ROOM_REQUEST_FAILED = "PLAYGROUND_ROOM_REQUEST_FAILED";
export const PLAYGROUND_ROOM_UPDATE_LANGUAGE_SUCCESSFULL = "PLAYGROUND_ROOM_UPDATE_LANGUAGE_SUCCESSFULL";
export const PLAYGROUND_ROOM_PARTICIPANT_JOINED = "PLAYGROUND_PARTICIPANT_JOINED";
export const PLAYGROUND_ROOM_PARTICIPANT_LEFT = "PLAYGROUND_ROOM_PARTICIPANT_LEFT";
export const CANDIDATE_INVITED_SUCCESSFULLY = "CANDIDATE_INVITED_SUCCESSFULLY";
export const PLAYGROUND_ROOM_ENDED_SUCCESSFULLY = "PLAYGROUND_ROOM_ENDED_SUCCESSFULLY";
export const CLEAR_PLAYGROUND_ROOM_STATE = "CLEAR_PLAYGROUND_ROOM_STATE";
export const CHANGE_EDITOR_CONFIG = "CHANGE_EDITOR_CONFIG";
export const PLAYGROUND_ROOM_UPDATE_CODE_EDITOR = "PLAYGROUND_ROOM_UPDATE_CODE_EDITOR";