import React, { Component } from 'react';
import {FiWatch, FiLogOut, FiBookOpen } from 'react-icons/fi';
import { IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { Link } from 'react-router-dom';

class PlaygroundReviewNavigation extends Component {
    state = {
        isNoteDialogOpen:true
    }

    openNoteDialog = () => {
        this.setState({isNoteDialogOpen:true})

    }

    closeNoteDialog= () => {
        this.setState({isNoteDialogOpen:false})
    }

    render() {

        const { title, duration } = this.props;
        const time = moment.duration(duration)._data;
        
        return (<div className="playground-review-navigation">
            <h4>{title}</h4>
            <div className="playground-review-nav-item">
                <Tooltip arrow title="Interview duration">
                <div>
                    <FiWatch size="20px" color="grey" />
                    <small >{time.hours + "h " + time.minutes + "m " + time.seconds + "s "}</small>
                </div>
                </Tooltip>
                <Tooltip title="Notes" arrow>
                <IconButton onClick={this.props.openNoteDialog}>
                    <FiBookOpen className="plaground-nav-icon"></FiBookOpen>
                </IconButton>
            </Tooltip>
                <Tooltip title="Exit from playground" arrow>
                    <Link to="/dashboard/playgrounds">
                        <IconButton>
                            <FiLogOut size="20px"></FiLogOut>
                        </IconButton>
                    </Link>
                </Tooltip>
            </div>
        </div>);
    }
}

export default PlaygroundReviewNavigation;