import { Drawer} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default function NotesDrawer(props) {

    return <Drawer open={props.isOpen} onClose={props.onClose}>
        {props.notes.length === 0 ?
            <Alert className="notes-drawer m-3" severity="info">No notes found</Alert> :
            props.notes.map(note => <div className="note-container m-1 p-3" key={note.id}>{note.value}</div>)}
    </Drawer>
}