import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    Button,
    CircularProgress,
    Tooltip
} from '@material-ui/core';
import { FiChevronsRight } from 'react-icons/fi';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-plain_text"
import "ace-builds/src-noconflict/ext-language_tools";
import * as actions from '../../infrastructure/store/actions/playgroundSession';

class CodeExecutionPanel extends Component {
    state = {}
    render() {
        return (<div className="code-execution-panel-container">
            {this.props.selectedLanguage ? <Button onClick={() => this.props.requestExecOutput()} variant="outlined">
                <FiChevronsRight color="grey" size="20px" />
                    Run Code
                </Button> :

                <div>
                    <Tooltip arrow title="Select language before executing code.">
                        <Button variant="outlined">
                            <FiChevronsRight color="grey" size="20px" />
                     Run Code
               </Button>
                    </Tooltip>

                </div>
            }
            <hr></hr>
            <div className="execution-output-container">
                {
                    this.props.isLoading ? <div>
                        <p>Executing...</p>
                        <CircularProgress ></CircularProgress>
                    </div> :
                        <AceEditor
                            className="execution-output-panel"
                            readOnly={true}
                            showPrintMargin={false}
                            showGutter={false}
                            mode="plain_text"
                            theme="github"
                            width="100%"
                            height="100%"
                            name="execution-panel"
                            value={this.props.executionOutput}
                            fontSize={14} />}
            </div>
        </div>);
    }
}
const mapStateToProps = (state) => {
    return {
        executionOutput: state.playgroundSession.executionOutput,
        isLoading: state.playgroundSession.isLoading,
        selectedLanguage: state.playgroundSession.language
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        requestExecOutput: () => dispatch(actions.requestExecOutput())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CodeExecutionPanel);