import * as actionTypes from '../actionTypes/playground';
import { httpSecured } from '../../service/axios';
import * as routes from '../../service/routes';

export const createPlayground = (title,candidateId,questionIds) => {
    return async (dispatch) => {
        dispatch(beginPlaygroundRequest());
        try {
            const { data } = await httpSecured.post(routes.PLAYGROUNDS, {title:title,candidateId:candidateId,questionIds:questionIds});
            dispatch(playgroundCreatedSuccess(data));
        } catch (ex) {
            dispatchError(dispatch, ex);
        }
    }
}

export const getAllUserOwnedPlaygrounds = (title, languageType, status, page, size) => {
    return async (dispatch, getState) => {
        dispatch(beginPlaygroundRequest())
        try {
            const url = createFetchPlaygroundLink(
                getState(), routes.USER_OWNED_PLAYGROUNDS, title, languageType, status, size, page);
            const { data, headers } = await httpSecured.get(url);
            dispatch(playgroundsFetchSuccessfull({
                playgrounds: data,
                pageCount: headers["x-total-count"]
            }));
        } catch (ex) {
            dispatchError(dispatch, ex);
        }
    }
}

export const gettAllUserInvitedPlaygrounds = (title, languageType, status, page, size) => {

    return async (dispatch, getState) => {
        dispatch(beginPlaygroundRequest());
        try {
            const url = createFetchPlaygroundLink(
                getState(), routes.USER_INVITED_PLAYGROUNDS, title, languageType, status, size, page);
            const { data, headers } = await httpSecured.get(url);
            dispatch(playgroundsFetchSuccessfull({
                playgrounds: data,
                pageCount: headers["x-total-count"]
            }));
        } catch (ex) {
            dispatchError(dispatch, ex);
        }
    }
}

export const getAllPlaygroundStatuses = () => {
    return async (dispatch) => {
        dispatch(beginPlaygroundRequest());
        try {
            const { data } = await httpSecured.get(routes.PLAYGROUND_STATUS);

            dispatch(playgroundStatusFetchSuccessfull(data));
        } catch (ex) {
            dispatchError(dispatch, ex);
        }
    }
}

export const deletePlayground = (id) => {
    return async (dispatch) => {
        dispatch(beginPlaygroundRequest());
        try {
            await httpSecured.delete(routes.PLAYGROUNDS + `/${id}`)
            dispatch(playgroundSuccesfullyDeleted(id));
        } catch (ex) {
            dispatchError(dispatch, ex);
        }
    }
}

export const updatePlayground = (id, payload) => {
    return async dispatch => {
        dispatch(beginPlaygroundRequest())
        try {
            await httpSecured.put(routes.PLAYGROUNDS + `/${id}`, payload);
            dispatch(playgroundUpdatedSuccessfully())
        } catch (ex) {
            dispatchError(dispatch, ex);
        }
    }
}

export const sharePlayground = (id, usersIds) => {
    return async (dispatch) => {
        dispatch(beginPlaygroundRequest())
        try {
            await httpSecured.post(routes.PLAYGROUNDS + "/" + id + "/invite-users", usersIds)
            dispatch(inviteUserToPlaygroundSuccessfull())
        } catch (ex) {
            dispatchError(dispatch, ex);
        }
    }
}

export const clearErrors = () => {
    return {
        type: actionTypes.CLEAR_PLAYGROUND_STATE
    }
}

const beginPlaygroundRequest = () => {
    return {
        type: actionTypes.BEGIN_PLAYGROUND_REQUEST
    }
}

const playgroundCreatedSuccess = (payload) => {
    return {
        type: actionTypes.PLAYGROUND_CREATED_SUCCESSFULLY,
        payload
    }
}

const playgroundRequestError = (payload) => {
    return {
        type: actionTypes.PLAYGROUND_REQUEST_FAILED,
        payload
    }
}

const playgroundsFetchSuccessfull = (payload) => {
    return {
        type: actionTypes.GET_ALL_PLAYGROUNDS_SUCCESSFULL,
        payload
    }
}
const playgroundStatusFetchSuccessfull = (payload) => {
    return {
        type: actionTypes.GET_ALL_STATUSES_SUCCESSFULL,
        payload
    }
}

const playgroundSuccesfullyDeleted = (payload) => {
    return {
        type: actionTypes.PLAYGROUND_DELETED_SUCCESSFULLY,
        payload
    }
}

const inviteUserToPlaygroundSuccessfull = () => {
    return {
        type: actionTypes.PLAYGROUND_SHARE_SUCCESSFULL
    }
}

const dispatchError = (dispatch, ex) => {
    if (ex.response) {
        dispatch(playgroundRequestError(ex.response.data.message))
    } else {
        dispatch(playgroundRequestError("Unexpected Error Ocurred"));
    }
}

const playgroundUpdatedSuccessfully = () => {
    return {
        type: actionTypes.PLAYGROUND_UPDATED_SUCCESSFULLY
    }
}

const createFetchPlaygroundLink = (state, baseUrl, title, languageType, status, size, page) => {

    const userId = state.auth.currentUser.userInfo.id;
    const url = baseUrl.replace("{userId}", userId);

    return `${url}?languageType=${languageType}&status=${status}&size=${size}&page=${page - 1}&title=${title}`;

}