import * as actionTypes from '../actionTypes/candidates';
import { httpSecured } from '../../service/axios';
import * as routes from '../../service/routes';


export const createCandidate = (payload) => {
    return async (dispatch) => {
        dispatch(beginRequest());
        try {
            const { data } = await httpSecured.post(routes.CANDIDATES, payload);
            dispatch(candidateCreatedSuccessully(data));
        } catch (ex) {
            dispatchError(dispatch, ex);
        }
    }
}

export const findAllCandidates = () => {
    return async (dispatch) => {
        dispatch(beginRequest());
        try {
            const { data } = await httpSecured.get(routes.CANDIDATES);
            dispatch(findAllCandidatesSuccessfull(data));
        } catch (ex) {
            dispatchError(dispatch, ex);
        }
    }
}

export const findAllByEmail = (email) => {
    return async (dispatch) => {
        dispatch(beginRequest())
        try{
            const {data} = await httpSecured.get(routes.CANDIDATES_BY_EMAIL_LIKE + email)
            dispatch(findAllCandidatesByEmailSuccessfull(data));
        }catch(ex){
            dispatchError(ex);
        }
    }
}

export const findAllByUserId = (page, size) => {
    return (dispatch, getState) => {
        dispatch(beginRequest());
        const userId = (getState().auth.currentUser.userInfo.id);
        httpSecured
            .get(routes.CANDIDATES_BY_USER_ID.replace("{id}", userId) + `?page=${page - 1}&size=${size}`)
            .then(response => {
                const { data, headers } = response;
                dispatch(findAllCandidatesSuccessfull({
                    pageCount: headers["x-total-count"],
                    candidates: data
                }));
            }).catch(er => {
                dispatchError(dispatch, er);
            })
    }
}

export const deleteCandidate = (id) => {

    return async (dispatch) => {
        dispatch(beginRequest())
        try{
            await httpSecured.delete(routes.CANDIDATES + "/" + id);
            dispatch(candidateSuccessfullyDeleted(id));
        }catch(ex){
            dispatchError(dispatch,ex);
        }
    }
}

export const updateCandidate = (id,data) => {

    return async (dispatch) => {
        dispatch(beginRequest())
        try{
            await httpSecured.put(routes.CANDIDATES + "/" + id,data);
            dispatch(candidateSuccessfullyUpdated(id,data));
        }catch(ex){
            dispatchError(dispatch,ex);
        }
    }
}

const beginRequest = () => {
    return {
        type: actionTypes.BEGIN_CANDIDATE_REQUEST,
        isLoading: true
    }
}

const candidateCreatedSuccessully = (payload) => {
    return {
        type: actionTypes.CREATE_CANDIDATE_FINISHED,
        payload
    }
}

const candidateSuccessfullyDeleted = (id) => {
    return {
        type:actionTypes.DELETE_CANDIDATE_FINISHED,
        payload:id
    }
}


const findAllCandidatesSuccessfull = (payload) => {
    return {
        type: actionTypes.FIND_ALL_CANDIDATES_FINISHED,
        payload
    }
}

const candidateRequestFailed = (payload) => {
    return {
        type: actionTypes.CANDIDATE_REQUEST_FAILED,
        payload
    }
}

const candidateSuccessfullyUpdated = (id,data) => {
    return {
        type:actionTypes.UPDATE_CANDIDATE_FINISHED,
        payload:{
            id,
            candidate:data
        }
    }
}

const findAllCandidatesByEmailSuccessfull = (payload) =>{
    return{
        type:actionTypes.FIND_CANDIDATES_BY_EMAIL_SUCCESSFULL,
        payload
    }
}

const dispatchError = (dispatch, ex) => {
    if (ex.response !== undefined) {
        dispatch(candidateRequestFailed(ex.response.data.message));
    } else {
        dispatch(candidateRequestFailed("Unexpected error ocurred."))
    }

}
