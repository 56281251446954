import { Chip, Tooltip } from '@material-ui/core';
import { FiUser} from 'react-icons/fi';
import React, { Component } from 'react';

class PlaygroundReviewParticipants extends Component {
    state = {}
    render() {

        const {candidateName} = this.props;

        return (<div className="playground-room-participants">     
            {candidateName &&
                <Tooltip arrow title="Candidate">
                    <Chip
                        className="online-participant-card"
                        icon={<FiUser size="15px" color="blue" />}
                        label={candidateName}
                        variant="outlined">
                    </Chip>
                </Tooltip>}
        </div>);
    }
}

export default PlaygroundReviewParticipants;