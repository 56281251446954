import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FiMoreVertical } from 'react-icons/fi';
import {
    IconButton,
    Popper,
    Paper,
    MenuItem,
    MenuList
} from '@material-ui/core';

class PlaygroundMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            ref: null
        }
        this.menuRef = React.createRef();
    }

    onClick = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {

        const ownedPlaygroundNav = <div>
            {this.props.playgroundStatus === "ENDED" ?
                <Link to={"/playgrounds/review/" + this.props.id}>
                    <MenuItem> Visit</MenuItem>
                </Link> :
                <Link to={"/playground-room/" + this.props.hash}>
                    <MenuItem> Join</MenuItem>
                </Link>}
            <Link to={"/dashboard/playgrounds/share/" + this.props.id}>
                <MenuItem> Share</MenuItem>
            </Link>
        </div>

        const invitedPlaygroundNav = <Link to={"/playground-room/" + this.props.hash}>
            {this.props.playgroundStatus === "ENDED" ?
                <Link to={"/playgrounds/review/" + this.props.id}>
                    <MenuItem> Visit</MenuItem>
                </Link> :
                <Link to={"/playground-room/" + this.props.hash}>
                    <MenuItem> Join</MenuItem>
                </Link>}
        </Link>

        return (
            <div className="playground-menu-button-position">
                <div className="playground-menu-button-position" ref={this.menuRef}>
                    <IconButton onClick={this.onClick}>
                        <FiMoreVertical size="20px" ></FiMoreVertical>
                    </IconButton>
                </div>
                <Popper open={this.state.isOpen} anchorEl={this.menuRef.current}>
                    <Paper elevation={3}>
                        <MenuList autoFocusItem={true}>
                            {this.props.isOwned ? ownedPlaygroundNav : invitedPlaygroundNav}
                        </MenuList>
                    </Paper>
                </Popper>
            </div>);
    }
}

export default PlaygroundMenu;