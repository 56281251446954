import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    TextField,
    Paper
} from '@material-ui/core';
import { FormGroup } from 'reactstrap';
import { Alert, Autocomplete } from '@material-ui/lab';
import { connect } from 'react-redux';
import * as userActions from '../../infrastructure/store/actions/users';
import * as playgroundActions from '../../infrastructure/store/actions/playground';

class PlaygroundShareForm extends Component {
    state = {
        selectedUser: null,
        searchParam: ""
    }

    handleInput = (event, value) => {
        this.setState({ selectedUser: value });
        if (event.target.id === "users" && event.target.value.length > 1) {
            this.setState({ searchParam: event.target.value });
        }
    }

    getAllUsers = () => {
        this.props.getAllUsers(this.state.searchParam);
    }

    sharePlayground = () => {
        const id = this.props.match.params.id;
        this.props.sharePlayground(id, [this.state.selectedUser.id])
    }

    render() {

        return (<Paper elevation={0} className="playground-form-container">
            <div className="playground-form">
                <FormGroup>
                    <h5>Share</h5>
                    <p>Share playground with other interviewers</p>
                    <Autocomplete fullWidth
                        onChange={this.handleInput}
                        value={this.state.selectedUser}
                        id="users"
                        options={this.props.users}
                        getOptionLabel={(option) => option.email}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                value={this.state.searchParam}
                                label="Users"
                                variant="outlined" />} />
                </FormGroup>

                <FormGroup>
                    <Link to="/dashboard/playgrounds">
                        <Button color="primary">Back</Button>
                    </Link>
                    {!this.state.selectedUser ? <Button disabled>Share</Button> : <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.sharePlayground}>
                        Share</Button>}
                </FormGroup>
                <FormGroup>
                    {this.props.isShared &&
                        <Alert>Playground shared with
                            <b> {this.state.selectedUser.firstName} {this.state.selectedUser.lastName}</b>
                        </Alert>}
                </FormGroup>

            </div>
        </Paper>);
    }
}
const mapStateToProps = (state) => {
    return {
        users: state.user.users,
        isShared: state.playground.isShared
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAllUsers: (email) => dispatch(userActions.getAllUsersByEmail(email)),
        sharePlayground: (playgroundId, usersIds) => dispatch(playgroundActions.sharePlayground(playgroundId, usersIds))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundShareForm);