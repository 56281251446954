import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress
} from '@material-ui/core';

class PlaygroundDeleteDialog extends Component {

    state = {
        selectedCandidate: {},
        isOpen: false
    }

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    render() {

        return (<div>
            <Dialog open={this.props.isOpen}>
                <DialogTitle>Delete Playground</DialogTitle>
                <DialogContent>
                    <DialogContentText>You are about to delete a playground, are you sure?</DialogContentText>
                    {this.props.isLoading && <CircularProgress className="align-self-center"></CircularProgress>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onClose()} color="primary">Cancel</Button>
                    <Button onClick={() => this.props.onDelete()} variant="outlined" color="secondary">Delete</Button>
                </DialogActions>
            </Dialog>
        </div >)

    }
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.playground.isLoading,
        isError: state.playground.isError,
        errorMessage: state.playground.errorMessage,
    }
}

export default connect(mapStateToProps, null)(PlaygroundDeleteDialog)