import * as actionTypes from '../actionTypes/notes';
import { httpSecured } from '../../service/axios';
import * as routes from '../../service/routes';


export const createNote = (hash, value) => {
    return async dispatch => {
        dispatch(beginRequest())
        try {
            await httpSecured.post(routes.PLAYGROUNDS + "/" + hash + "/notes", { value })
            dispatch(noteCreatedSucessfully({ id: Math.floor(Math.random() * 999) + 1, value }))
        } catch (ex) {
            dispatchError(dispatch, ex);
        }
    }
}

export const getAllNotes = (hash) => {
    return async dispatch => {
        dispatch(beginRequest())
        try {
            const { data } = await httpSecured.get(routes.PLAYGROUNDS + "/" + hash + "/notes")
            dispatch(notesFetchedSuccessfully(data))
        } catch (ex) {
            dispatchError(dispatch, ex);
        }
    }
}

export const clearState = () => {
    return {
        type:actionTypes.CLEAR_NOTE_STATE
    }
}

const noteCreatedSucessfully = (payload) => {
    return {
        type: actionTypes.CREATE_NOTE_SUCCESSFULL,
        payload
    }
}

const notesFetchedSuccessfully = (payload) => {
    return {
        type: actionTypes.GET_ALL_NOTES_SUCCESSFULL,
        payload
    }
}

const beginRequest = () => {
    return {
        type: actionTypes.BEGIN_NOTES_REQUEST
    }
}

const noteRequestFailed = (payload) => {
    return {
        type: actionTypes.NOTE_REQUEST_FAILED,
        payload
    }
}

const dispatchError = (dispatch, ex) => {
    if (ex.response.data.detail) {
        dispatch(noteRequestFailed(ex.response.data.detail));
    } else {
        dispatch(noteRequestFailed("An unexpected error occurred while requesting notes."));
    }
}