import { CircularProgress } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import * as userTypes from '../../../infrastructure/service/userType';

class PrivateRoute extends Component {
    state = {}
    render() {

        if(!this.props.userDataLoaded || this.props.isLoading){
            return <CircularProgress></CircularProgress>
        }

        return this.props.isAuthenticated && this.props.userType === userTypes.USER ?
         this.props.children : <Redirect to="/login"/>
    }
}

const mapStateToProps = (state) => {

    return {
        isAuthenticated: state.auth.isAuthenticated,
        userType: state.auth.userType,
        userDataLoaded:state.auth.userDataLoaded,
        isLoading:state.auth.isLoading
    }
}

export default connect(mapStateToProps, null)(PrivateRoute);