import React, { Component } from 'react';
import * as actions from '../../infrastructure/store/actions/questions';
import * as languageActions from '../../infrastructure/store/actions/language';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-plain_text"
import { Button, Select, TextField, FormControl, InputLabel, Snackbar} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert } from '@material-ui/lab';

class CreateQuestionForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            codeEditor: "",
            languageId: null,
            title: "",
            questionType: null,
            errorMessage:null
        }
    }

    componentDidMount = () => {
        this.props.getAllLanguages();
    }

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleLanguageChange = (event) => {
        if (event.target.value == 0) {
            this.clearContent();
            return;
        }
        const language = this.props.languages.find(lang => lang.id == event.target.value);
        this.setState({
            codeEditor: language.template,
            languageId: language.id,
            languageName:language.name.toLowerCase()
        })
    }

    handleEditorInput = (value) => {
        this.setState({ codeEditor: value });
    }

    clearContent = () => {
        this.setState({ codeEditor: "" });
    }

    createQuestion = () => {
        const { title, languageId, codeEditor } = this.state;

        if(title.length <= 0){
            this.setState({errorMessage:"Title cannot be blank."})
            return;
        }else if(codeEditor.length <= 0){
            this.setState({errorMessage:"Question content cannot blank."})
            return;
        }

        this.props.createQuestion(title, codeEditor, languageId);
        this.props.history.push("/dashboard/questions");
    }

    render() {

        return (
            <div>
                <h5 className="mb-3">Create an interview question</h5>
                <div className="mb-3 bt-3">
                    <FormControl>
                        <TextField onChange={this.handleInput} id="title" variant="outlined" fullWidth label="Title" />
                    </FormControl>
                    <FormControl variant="outlined" className="ml-2" >
                        <InputLabel >Language</InputLabel>
                        <Select onChange={this.handleLanguageChange} id="languageId" native label="Language">
                            <option aria-label="Select" value={0}>None</option>
                            {this.props.languages.map(lang =>
                                <option key={lang.id} value={lang.id}>{lang.name}</option>)}
                        </Select>
                    </FormControl >
                </div>

                <AceEditor
                    showPrintMargin={false}
                    mode={this.state.languageName}
                    theme={"github"}
                    onChange={this.handleEditorInput}
                    width="100%"
                    name="code-editor"
                    value={this.state.codeEditor}
                    fontSize={16}
                />
                <div className="mb-3 mt-3">
                    <Button onClick={this.createQuestion} className="mr-3" variant="outlined" color="primary">Create</Button>
                    <Button variant="outlined" color="primary" onClick={this.clearContent}>Clear</Button>
                    <Link to="/dashboard/questions">
                        <Button variant="outlined" color="primary" className="float-right" onClick={this.clearContent}>Back</Button>
                    </Link>

                </div>
                <Snackbar autoHideDuration={5000} anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }} open={this.state.errorMessage !== null}>
                    <Alert icon={false} style={{backgroundColor:"tomato",color:"white"}} onClose={() => this.setState({errorMessage:null})}>{this.state.errorMessage}</Alert>
                </Snackbar>
                {this.props.isError && <Alert onClose={() => this.props.clearState()} severity="error">{this.props.errorMessage}</Alert>}
                <Snackbar autoHideDuration={5000} anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }} open={this.props.isCreated}>
                    <Alert icon={false} style={{backgroundColor:"lightgreen",color:"white"}} onClose={() => this.props.clearState()}>Question created successfully</Alert>
                </Snackbar>
                {this.props.isError && <Alert onClose={() => this.props.clearState()} severity="error">{this.props.errorMessage}</Alert>}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages.languages,
        isError:state.languages.isError,
        errorMessage:state.languages.errorMessage,
        isCreated: state.question.isCreated
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        createQuestion: (title, content, languageId) => dispatch(actions.createQuestion(title, content, languageId)),
        getAllLanguages: () => dispatch(languageActions.getAllLanguages()),
        clearState: () => dispatch(actions.clearState())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestionForm);