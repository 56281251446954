import {httpSecured} from '../../service/axios';
import * as actionTypes from '../actionTypes/questions';
import * as routes from '../../service/routes';

export const createQuestion = (title,content,languageId) => {
    return async dispatch => {
        dispatch(attemptQuestionRequest());
        try{
            const requestData = {
                title,
                content,
                languageIds: languageId ? [languageId] : null
            }
            await httpSecured.post(routes.QUESTION_ROUTE,requestData);
            const randomId = Math.floor(Math.random() * 999) + 1;
            dispatch(questionCreatedSuccesfully({
                ...requestData,
                id: randomId
            }))
        }catch(ex){
            console.log(ex.response);
            dispatch(dispatchError(ex));
        }

    }
}

export const getAllQuestions = (userId) => {
    return async dispatch => {
        dispatch(attemptQuestionRequest());
        try{
            const {data} = await httpSecured.get(routes.QUESTION_ROUTE);
            dispatch(questionsFetchedSuccessfully(data));
        }catch(ex){
            dispatch(dispatchError(ex))
        }
    }
}

export const deleteQuestion = (questionId) => {
    return async dispatch => {
        dispatch(attemptQuestionRequest())
        try{
            await httpSecured.delete(routes.QUESTION_ROUTE + "/" + questionId);
            dispatch({
                type:actionTypes.QUESTION_DELETED_SUCCESSFULLY,
                payload:questionId
            })
        }catch(ex){
            dispatchError(ex);
        }
    }
}

export const clearState = () => {
    return dispatch => {
        dispatch(clearStateAction())
    }
}

const clearStateAction = () => {
    return {
        type:actionTypes.CLEAR_QUESTION_STATE
    }
}

const attemptQuestionRequest = () => {
    return {
        type: actionTypes.QUESTION_REQUEST_ATTEMPT,
    }
}

const dispatchError = (ex) => {

    let message = "Unexpected Error Occurred";
    if(ex.response){
        message = ex.response.data.message;
    }

    return {
        type:actionTypes.QUESTION_REQUEST_FAILED,
        payload:message
    }
}

const questionCreatedSuccesfully = (createdQuestion) => {
    return {
        type:actionTypes.QUESTION_CREATED_SUCCESSFULLY,
        payload:createdQuestion
    }
}

const questionsFetchedSuccessfully = (questions) => {
    return {
        type:actionTypes.GET_ALL_QUESTIONS_SUCCESSFULL,
        payload:questions
    }
}