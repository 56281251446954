import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import reducer from './reducers/authentication';
import candidateReducer from './reducers/candidates';
import languageReducer from './reducers/languages';
import playgroundReducer from './reducers/playground';
import playgroundSessionReducer from './reducers/playgroundSession';
import userReducer from './reducers/users';
import noteReducer from './reducers/notes';
import playgroundReviewReducer from './reducers/playgroundReview';
import questionReducer from './reducers/questions';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [];
middlewares.push(thunk);
// const { createLogger } = require("redux-logger");
// const logger = createLogger();
// middlewares.push(logger);

const rootReducer = combineReducers({
    auth: reducer,
    candidate: candidateReducer,
    languages:languageReducer,
    playground:playgroundReducer,
    playgroundSession:playgroundSessionReducer,
    user:userReducer,
    note:noteReducer,
    playgroundReview:playgroundReviewReducer,
    question:questionReducer
});

export default createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares))
);