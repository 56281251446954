import * as actionTypes from '../actionTypes/notes';

const initialState = {
    notes:[],
    isLoading:false,
    isError:false,
    errorMessage:"",
    isCreated:false
}

const reducer = (state = initialState,action) => {

    if(action.type === actionTypes.BEGIN_NOTES_REQUEST){
        return beginRequest(state);
    }else if(action.type === actionTypes.CREATE_NOTE_SUCCESSFULL){
        return noteCreated(state,action);
    }else if(action.type === actionTypes.GET_ALL_NOTES_SUCCESSFULL){
        return notesFetched(state,action);
    }else if(action.type === actionTypes.CLEAR_NOTE_STATE){
        return clearState(state)
    }else if(action.type === actionTypes.NOTE_REQUEST_FAILED){
        return handleError(state,action)
    }else {
        return state;
    }
}

const beginRequest = (state) => {
    return {
        ...state,
        isLoading:true,
        isCreated:false
    }
}

const noteCreated = (state,action) =>{
    return {
        ...state,
        isCreated:true,
        notes: [...state.notes,action.payload],
        isLoading:false
    }
}
const notesFetched =(state,action) => {
    return {
        ...state,
        notes:action.payload,
        isLoading:false
    }
}
const clearState = (state) => {
    return {
        ...state,
        isError:false,
        isCreated:false,
        errorMessage:false,
        isLoading:false
    }
}

const handleError = (state,action) => {
    return {
        ...state,
        isError:true,
        isLoading:false,
        errorMessage:action.payload
    }
}

export default reducer;