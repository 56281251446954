import Stomp from 'stompjs';
import * as actionTypes from '../actionTypes/playgroundSession';
import * as userTypes from '../../service/userType';
import { httpSecured } from '../../service/axios';

const publishRoute = "/app/playground-session/";
const subscribeRoute = "/topic/playground-session/";
const errorRoute = "/user/queue/error";
const participantTypeHeaderName = "Participant-Type";
const playgroundHashHeaderName = "Playground-Hash";
let stomp;
let playgroundRoomHash;
let headers;


export const wsConnect = (hash) => {

    return (dispatch, getState) => {

        dispatch(beginSessionRequest())

        stomp = Stomp.client(process.env.REACT_APP_WS_URL);
        stomp.debug = null;
        playgroundRoomHash = hash;

        const token =
            getState().auth.userType === userTypes.USER
                ? localStorage.getItem("token") : localStorage.getItem("candidate-token");

        const { userType } = getState().auth;
        const currentUser = getCurrentUser(userType, getState().auth);

        headers = {
            Authorization: token,
            [participantTypeHeaderName]: userType,
            [playgroundHashHeaderName]: hash
        }

        stomp.connect(headers, () => setupWatchers(dispatch, hash, currentUser), (error) => onError(error, dispatch))
    }
}

const setupWatchers = (dispatch, hash, currentUser) => {

    dispatch(stompSuccessfullyConnected(stomp))
    stomp.subscribe(publishRoute + hash, payload => {
        dispatch(initialDataReceived(JSON.parse(payload.body)));
    })

    stomp.subscribe(subscribeRoute + hash + "/change-editor-content", payload => {

        const receivedContent = JSON.parse(payload.body);
        const {updatedBy} = receivedContent;
        if(currentUser.userType === updatedBy.participantType ){
           if(currentUser.id !== updatedBy.participantId){
               dispatch(editorContentReceived(receivedContent));
           }
        }else{
            dispatch(editorContentReceived(receivedContent));
        }
    })

    stomp.subscribe(subscribeRoute + hash + "/execute-code", payload => {
        dispatch(execOutputReceived(JSON.parse(payload.body).output));
    })
    stomp.subscribe(subscribeRoute + hash + "/update-language", payload => {
        dispatch(languageUpdateReceived(JSON.parse(payload.body)))
    })
    stomp.subscribe(errorRoute, payload => {
        dispatchError(dispatch, JSON.parse(payload.body));
    })
    stomp.subscribe(subscribeRoute + hash + "/join", payload => {
        dispatch(participantJoined(JSON.parse(payload.body)));
    })
    stomp.subscribe(subscribeRoute + hash + "/leave", payload => {
        dispatch(leavePlayground(JSON.parse(payload.body)));
    })
    stomp.subscribe(subscribeRoute + hash + "/end", payload => {
        dispatch(playgroundRoomEnded(payload.body));
    })

    stomp.send(publishRoute + playgroundRoomHash + "/join", headers, {});

}
export const requestExecOutput = () => {
    return dispatch => {
        dispatch(execOutputRequested());
        stomp.send(publishRoute + playgroundRoomHash + "/execute-code", {}, {});
    }
}
export const sendEditorContent = (content) => {
    stomp.send(publishRoute + playgroundRoomHash + "/change-editor-content", {}, JSON.stringify({ content: content }));
}

export const updatePlaygroundLanguage = (languageId) => {
    stomp.send(publishRoute + playgroundRoomHash + "/update-language", {}, JSON.stringify({ languageId: languageId }))
}

export const inviteCandidate = (invitationType) => {
    return async dispatch => {
        try {
            if (invitationType.invitationEmail) {
                await httpSecured.post("/online-interviews/playgrounds/" + playgroundRoomHash + "/invite-candidate");
                dispatch(candidateInvitedSuccessfully(""))
            }
            if (invitationType.shareableLink) {
                const response =
                    await httpSecured.get("/online-interviews/playgrounds/" + playgroundRoomHash + "/invitation-link");
                dispatch(candidateInvitedSuccessfully(response.data.link))
            }
        } catch (ex) {
            dispatchError(dispatch, "Candidate coudn't be invited.");
        }
    }
}

export const clearPlaygroundRoomState = () => {
    return {
        type: actionTypes.CLEAR_PLAYGROUND_ROOM_STATE
    }
}

export const changeEditorConfig = (payload) => {
    return {
        type: actionTypes.CHANGE_EDITOR_CONFIG,
        payload
    }
}

export const joinPlayground = () => {
    stomp.send(publishRoute + playgroundRoomHash + "/join", headers, {});
}

export const leavePlayground = (payload) => {
    return {
        type: actionTypes.PLAYGROUND_ROOM_PARTICIPANT_LEFT,
        payload
    }
}

export const wsDisconnect = () => {
    stomp.disconnect();
}

export const endPlaygroundRoom = () => {
    stomp.send(publishRoute + playgroundRoomHash + "/end");
}

export const updateCodeEditorContent = (payload) => {
    return {
        type:actionTypes.PLAYGROUND_ROOM_UPDATE_CODE_EDITOR,
        payload
    }
}


const playgroundRoomEnded = (payload) => {
    return {
        type: actionTypes.PLAYGROUND_ROOM_ENDED_SUCCESSFULLY
    }
}

const languageUpdateReceived = (payload) => {
    return {
        type: actionTypes.PLAYGROUND_ROOM_LANGUAGE_CHANGE_RECEIVED,
        payload
    }
}

const stompSuccessfullyConnected = (payload) => {
    return {
        type: actionTypes.PLAYGROUND_ROOM_CONNECT_SUCCESSFULL,
        payload
    }
}

const initialDataReceived = (payload) => {

    const { playgroundSession, principal } = payload;
    const { language,
        codeEditorContent: editorContent,
        interviewers,
        candidate } = playgroundSession;
    const data = {
        editorContent,
        language,
        interviewers,
        principal,
        candidate
    }
    return {
        type: actionTypes.PLAYGROUND_ROOM_FETCH_INITIAL_DATA_SUCCESSFULL,
        payload: data
    }
}
const editorContentReceived = (payload) => {
    return {
        type: actionTypes.PLAYGROUND_ROOM_CODE_EDITOR_CONTENT_RECEIVED,
        payload: payload.content
    }
}

const execOutputReceived = (payload) => {
    return {
        type: actionTypes.PLAYGROUND_ROOM_EXEC_OUTPUT_RECEIVED,
        payload
    }
}
const execOutputRequested = () => {
    return {
        type: actionTypes.PLAYGROUND_ROOM_EXEC_OUTPUT_REQUESTED,
    }
}

const onError = (error, dispatch) => {
    if (error) {
        console.log(error);
        dispatchError(dispatch, JSON.parse(error.body).detail);
    }
}

const candidateInvitedSuccessfully = (payload) => {
    return {
        type: actionTypes.CANDIDATE_INVITED_SUCCESSFULLY,
        payload
    }
}

const beginSessionRequest = () => {
    return {
        type: actionTypes.BEGIN_PLAYGROUND_ROOM_REQUEST
    }
}

const playgroundRoomRequestFailed = (payload) => {
    return {
        type: actionTypes.PLAYGROUND_ROOM_REQUEST_FAILED,
        payload
    }
}

const dispatchError = (dispatch, payload) => {

    const errorMessage = payload.detail ? payload.detail : JSON.stringify(payload);
    dispatch(playgroundRoomRequestFailed(errorMessage));
}


const participantJoined = (payload) => {
    return {
        type: actionTypes.PLAYGROUND_ROOM_PARTICIPANT_JOINED,
        payload
    }
}
const getCurrentUser = (userType, state) => {
    if (userType === userTypes.CANDIDATE) {
        return {
            userType,
            id:state.currentUser.id
        }
    } else if (userType === userTypes.USER) {
        return {
            userType,
            id:state.currentUser.userInfo.id
        }
    }
}