import React, { Component } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

class QuestionCardSelectable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active:false
        };
    }
    toggle = () => {
        const active = !this.state.active;
        if(active){
            this.props.onSelected(this.props.id)
        }else{
            this.props.onDeselected(this.props.id);
        }
        this.setState({active})
    }
    

    render() {
        const cardClass = !this.state.active ? "code-snippet-container code-snippet-selectable" : "code-snippet-container code-snippet-active";
        return <div onClick={this.toggle} className={cardClass}>
            <div>
                <div className="code-snippet-container-header">
                    <p className="float-right">Language: {this.props.languages.length == 0 ? "None" : this.props.languages[0].name}</p>
                    <p>{this.props.title}</p>
                </div>
                <SyntaxHighlighter className="code-snippet" language="java" showLineNumbers={true} showInlineLineNumbers={true}>
                    {this.props.content}
                </SyntaxHighlighter>
            </div>
        </div>
    }
}

export default QuestionCardSelectable;