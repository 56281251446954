
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Col } from 'reactstrap';
import Sidebar from '../../components/Sidebar/sidebar';
import Toolbar from '../../components/Commons/Toolbar/toolbar';
import Candidates from './Candidates/candidates';
import Playgrounds from './Playgrounds/playgrounds';
import UpdateCandidateDialog from '../../components/Candidate/updateCandidateDialog';
import CreateCandidateDialog from '../../components/Candidate/createCandidateDialog';
import PlaygroundShareForm from '../../components/Playground/playgroundShareForm';
import './dashboard.css';
import PlaygroundUpdateForm from '../../components/Playground/playgroundUpdateForm';
import Questions from './Questions/questions';
import CreateQuestionForm from '../../components/Questions/createQuestionForm';
import PlaygroundCreateCompontent from '../../components/Playground/playgroundCreateCompontent';

class Dashboard extends Component {
    state = {
        sideBarToggled: false
    }
    routes = [
        {

            path: "/dashboard/candidates",
            component: Candidates,
            exact: true
        },
        {
            path: "/dashboard/playgrounds",
            component: Playgrounds,
            exact: true
        },
        {
            path: "/dashboard/questions",
            component: Questions,
            exact:true
        },
        {
            path: "/dashboard/playgrounds/create",
            component: PlaygroundCreateCompontent
        },
        {
            path: "/dashboard/candidates/create",
            component: CreateCandidateDialog
        },
        {
            path: "/dashboard/candidates/update",
            component: UpdateCandidateDialog
        },
        {
            path: "/dashboard/playgrounds/share/:id",
            component: PlaygroundShareForm
        },
        {
            path: "/dashboard/playgrounds/update",
            component: PlaygroundUpdateForm
        },
        {
            path: "/dashboard/questions/create",
            component: CreateQuestionForm
        }
    ]

    toggleSideBar = (toggled) => {
        this.setState({ sideBarToggled: toggled });
    }

    render() {
        return (
            <div>
                <div className="dashboard-container">
                    <Sidebar isToggled={this.state.sideBarToggled} toggle={this.toggleSideBar}></Sidebar>
                    <Col className="dashboard-pannel-container">
                        <Toolbar toggle={this.toggleSideBar}></Toolbar>
                        <Switch>
                            {this.routes.map(route =>
                                <Route key={route.path} {...route}></Route>)}
                        </Switch>
                    </Col>
                </div>
            </div>
        );
    }
}

export default Dashboard;