import * as actionTypes from '../actionTypes/questions';

const initialState = {
    questions:[],
    isLoading:false,
    isError:false,
    errorMessage:null,
    isCreated:false
}

const reducer = (state = initialState,action) => {

    if(action.type === actionTypes.QUESTION_REQUEST_ATTEMPT ){
        return beginRequest(state);
    }else if(action.type === actionTypes.QUESTION_CREATED_SUCCESSFULLY){
        return questionCreated(state,action);
    }else if(action.type === actionTypes.GET_ALL_QUESTIONS_SUCCESSFULL){
        return questionsFetched(state,action);
    }else if(action.type === actionTypes.QUESTION_REQUEST_FAILED){
        return handleError(state,action)
    }else if(action.type === actionTypes.CLEAR_QUESTION_STATE){
        return clearState(state);
    }else if(action.type === actionTypes.QUESTION_DELETED_SUCCESSFULLY){
        return questionDeletedSuccessfully(state,action);
    }else {
        return state;
    }
}

function questionDeletedSuccessfully(state,action){

    const questions = [...state.questions];
    const deletedQuestionId = action.payload;
    return {
        ...state,
        isLoading:false,
        questions:questions.filter(question => question.id !== deletedQuestionId)
    }

}

const clearState = (state) => {
    return {
        ...state,
        isLoading:false,
        isCreated:false,
        isErorr:false,
        errorMessage:null
    }
}

const beginRequest = (state) => {
    return {
        ...state,
        isLoading:true,
        isCreated:false,
        isError:false
    }
}

const questionCreated = (state,action) =>{
    return {
        ...state,
        isCreated:true,
        notes: [action.payload,...state.questions],
        isLoading:false
    }
}
const questionsFetched =(state,action) => {
    return {
        ...state,
        questions:action.payload,
        isLoading:false
    }
}
const handleError = (state,action) => {
    return {
        ...state,
        isError:true,
        isLoading:false,
        errorMessage:action.payload
    }
}

export default reducer;