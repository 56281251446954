import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { IconButton, Tooltip, Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';
import { FiLogOut, FiUserPlus, FiSettings, FiXCircle, FiBookOpen } from 'react-icons/fi';
import * as userTypes from '../../infrastructure/service/userType';
import * as actions from '../../infrastructure/store/actions/playgroundSession';
import { Alert } from '@material-ui/lab';

class PlaygroundRoomNavigation extends Component {
    state = {}

    render() {

        const userNavigation = <div>
            <Tooltip title="Invite candidate for an interview">
                <IconButton onClick={this.props.openInvitationDialog}>
                    <FiUserPlus className="plaground-nav-icon"></FiUserPlus>
                </IconButton>
            </Tooltip>
            <Tooltip title="Notes" arrow>
                <IconButton onClick={this.props.openNoteDialog}>
                    <FiBookOpen className="plaground-nav-icon"></FiBookOpen>
                </IconButton>
            </Tooltip>
            <Tooltip title="Configure Code Editor" arrow>
                <IconButton onClick={this.props.openConfig}>
                    <FiSettings className="plaground-nav-icon" />
                </IconButton>
            </Tooltip>
            <Tooltip title="End playground room" arrow>
                <IconButton onClick={() => actions.endPlaygroundRoom()}>
                    <FiXCircle className="plaground-nav-icon" />
                </IconButton>
            </Tooltip>
            <Link to="/dashboard/playgrounds">
                <Tooltip title="Exit from a playground" arrow>
                    <IconButton onClick={this.props.onLeave}>
                        <FiLogOut className="plaground-nav-icon"></FiLogOut>
                    </IconButton>
                </Tooltip>
            </Link>
        </div>

        const candidateNavigation = <div>
            <Link to="/dashboard/playgrounds">
                <Tooltip title="Exit from a playground" arrow>
                    <IconButton onClick={this.props.onLeave}>
                        <FiLogOut className="plaground-nav-icon"></FiLogOut>
                    </IconButton>
                </Tooltip>
            </Link>
        </div>

        return (<div className="playgroundroom-navigation-top">
            {this.props.userType === userTypes.USER ? userNavigation : candidateNavigation}
            <Dialog open={this.props.hasEnded}>
                <DialogContent>
                    <Alert severity="warning" elevation={0}>Playground room has ended</Alert>
                </DialogContent>
                <DialogActions>
                    <Link to="/dashboard/playgrounds">
                        <Button >Leave</Button>
                    </Link>
                </DialogActions>
            </Dialog>
        </div>);
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        inviteCandidate: () => dispatch(actions.inviteCandidate()),
        clearState: ()=>dispatch(actions.clearPlaygroundRoomState())
    }
}
const mapStateToProps = (state) => {
    return {
        userType: state.auth.userType,
        hasEnded: state.playgroundSession.hasEnded
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundRoomNavigation);