import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import * as actions from '../../infrastructure/store/actions/authentication';
import LandingPage from '../LandingPage/landingPage';
import LoginPage from '../LoginPage/loginPage';
import PrivateRoute from '../../components/Commons/PrivateRoute/privateRoute';
import Dashboard from '../Dashboard/dashboard';
import PlaygroundRoom from '../PlaygroundRoom/playgroundRoom';
import PlaygroundRoomRoute from '../../components/Commons/PrivateRoute/playgroundRoomRoute';
import CandidateAuthenticationRoute from '../../components/PlaygroundRoom/candidateAuthenticationRoute';
import PlaygroundReviewRoom from '../../containers/PlaygroundReviewRoom/playgroundReviewRoom';

class App extends Component {
    state = {}

    componentDidMount = () => {
        this.props.onCheckAuthState();
    }
    render() {
        const routes = [
            {
                path: "/login",
                component: LoginPage,
                private: false
            },
            {
                path:"/editor",
                component:PlaygroundRoom,
                private:false
            },
            {
                path: "/",
                component: LandingPage,
                private: false,
                exact: true
            },
            {
                path: "/dashboard/",
                component: Dashboard,
                private: true
            },
            {
                path:"/playground-room/:hash",
                component: PlaygroundRoomRoute,
                private:false
            },
            {
               path:"/playgrounds/join",
               component:CandidateAuthenticationRoute,
               private:false
            },{
                path:"/playgrounds/review/:id",
                component:PlaygroundReviewRoom,
                private:true,
            }
        ]
        return (<Switch>
            {routes.filter(route => !route.private).map(route => <Route key={route.path} {...route}></Route>)}
            <PrivateRoute>
                {routes.filter(route => route.private).map(route => <Route key={route.path} {...route}></Route>)}
            </PrivateRoute>

        </Switch>);
    }
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCheckAuthState: () => dispatch(actions.verifyAuthentication())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
