import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../infrastructure/store/actions/candidates';
import { FiEdit, FiTrash2, FiMail, FiUser,FiZap } from 'react-icons/fi';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

class CandidateTable extends Component {
    state = {}

    createCandidateLink = (candidate) => {
        const {id,email,firstName,lastName} = candidate;

        return `/dashboard/candidates/update?id=${id}&email=${email}&firstName=${firstName}&lastName=${lastName}`;
    }

    getCandidateLevel = (level) => {
        return level ? level.charAt(0).toUpperCase() + level.slice(1).toLowerCase() : "Not Selected"
    }

    render() {

        return (<div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Email</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Surname</TableCell>
                            <TableCell>Level</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.candidates.map(candidate => {
                            return (
                                <TableRow key={candidate.id}>
                                    <TableCell>
                                        <FiMail className="candidate-icon"/>
                                        <p className="candidate-text">{candidate.email}</p>
                                    </TableCell>
                                    <TableCell>
                                        <FiUser className="candidate-icon"/>
                                        {candidate.firstName}
                                    </TableCell>
                                    <TableCell>{candidate.lastName}</TableCell>
                                    <TableCell>
                                    <FiZap className="candidate-icon"></FiZap>
                                        {this.getCandidateLevel(candidate.level)}</TableCell>
                                    <TableCell align="right">
                                        <Link to={this.createCandidateLink(candidate)}>
                                            <IconButton>
                                                <FiEdit size="20px" />
                                            </IconButton>
                                        </Link>
                                        <IconButton onClick={() => this.props.onDelete(candidate)}>
                                            <FiTrash2 size="20px" />
                                        </IconButton>

                                    </TableCell>
                                </TableRow>);
                        })}
                    </TableBody>
                </Table>
                {this.props.candidates.length === 0 &&
                    <div className="p-3">
                        <Alert severity="info">No candidates available</Alert>
                    </div>}
            </TableContainer>
        </div>);
    }
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.candidate.isLoading,
        isError: state.candidate.isError,
        errorMessage: state.candidate.errorMessage,
        candidates: state.candidate.candidates,
        currentUser: state.auth.currentUser
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        findAllCandidates: () => dispatch(actions.findAllCandidates())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CandidateTable);
