import React, { Component } from 'react';
import { connect } from "react-redux";
import * as actions from '../../infrastructure/store/actions/authentication';

import { TextField, Button, CircularProgress, Avatar } from '@material-ui/core';
import { Form, FormGroup } from 'reactstrap';
import Alert from '@material-ui/lab/Alert';
import './loginPage.css';
import { FiUnlock } from 'react-icons/fi';
import { Redirect } from 'react-router';

class LoginPage extends Component {
    state = {
        username: "",
        password: "",
    }

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleLogin = () => {
        this.props.authenticate({
            username: this.state.username,
            password: this.state.password
        })
    }

    render() {

        if (this.props.isAuthenticated && this.props.userType === "USER") {
            return <Redirect to="/dashboard/playgrounds"></Redirect>
        }

        const header = "Sign it to </Codish>"
        return (
            <div className="login-container">

            <div className="login-form">
                <Form>
                    <Avatar className="mx-auto">
                        <FiUnlock />
                    </Avatar>
                    <h5 className="login-header">{header}</h5>
                    <hr></hr>
                    <FormGroup>
                        <TextField variant="outlined" fullWidth color="primary"
                            onChange={this.handleInput} required id="username" label="Username" />
                    </FormGroup>
                    <FormGroup>
                        <TextField variant="outlined" fullWidth color="primary"
                            onChange={this.handleInput} required id="password" label="Password" type="password" />
                    </FormGroup>
                    <FormGroup>
                        <Button variant="contained" fullWidth color="primary"
                            onClick={this.handleLogin}>Login</Button>
                    </FormGroup>
                    <div className="w-100 text-center display-flex justify-content-center">
                        {this.props.isLoginError && <Alert className="text-center" severity="error">{this.props.errorMessage}</Alert>}
                        {this.props.isLoading && <CircularProgress></CircularProgress>}
                    </div>
                </Form>
            </div>
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading,
        errorMessage: state.auth.errorMessage,
        isLoginError: state.auth.isLoginError,
        isAuthenticated: state.auth.isAuthenticated,
        userType: state.auth.userType
    };
};

const mapDispatchToProps = dispatch => {
    return {
        authenticate: data => dispatch(actions.authenticate(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);