import React, { Component } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

class SnippetCard extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="code-snippet-container">
                    <div className="code-snippet-container-header">
                        <p className="float-right">Language: {this.props.languages.length === 0 ? "None" : this.props.languages[0].name}</p>
                        <p>{this.props.title}</p>
                    </div>
                    <SyntaxHighlighter className="code-snippet" language="java" showLineNumbers={true} showInlineLineNumbers={true}>
                        {this.props.content}
                    </SyntaxHighlighter>
                    {this.props.nav}
            </div>
        );
    }
}

export default SnippetCard;