import * as actionTypes from '../actionTypes/playgroundReview';

const initialState = {
    playground:null,
    isError:false,
    errorMessage:"",
    isLoading:false
}

const reducer = (state=initialState,action) => {
    
    if(action.type===actionTypes.PLAYGROUND_REVIEW_BEGIN_REQUEST){
        return beginRequest(state);
    }else if(action.type === actionTypes.PLAYGROUND_REVIEW_GET_PLAYGROUND_SUCCESSFULL){
        return playgroundFetched(state,action);
    }else if(action.type === actionTypes.PLAYGROUND_REVIEW_REQUEST_ERROR){
        return playgroundReviewError(state,action);
    }else {
        return state;
    }
}

const beginRequest = (state) => {
    return {
        ...state,
        isLoading:true,
        isError:false
    }
}
const playgroundFetched = (state,action) =>{
    return {
        ...state,
        isLoading:false,
        playground:action.payload
    }
}

const playgroundReviewError = (state,action) => {
    return {
        ...state,
        isLoading:false,
        isError:true,
        errorMessage:action.payload
    }
}

export default reducer;