import React, { Component } from 'react';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/theme-terminal";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/ext-language_tools"
import "ace-builds/src-noconflict/mode-plain_text"
import { connect } from 'react-redux';
import * as actions from '../../infrastructure/store/actions/playgroundSession';

class CodeEditor extends Component {

    onChange = (value) => {  
        actions.sendEditorContent(value);
        this.props.updateCodeEditor(value);
    }
    render() {

        const { theme, fontSize } = this.props.editorConfig;
        let language = "plain_text";
        if(this.props.playgroundLanguage){
            language = this.props.playgroundLanguage.name.toLowerCase();
        }

        return (
            <AceEditor
                showPrintMargin={false}
                mode={language}
                theme={theme}
                onChange={this.onChange}
                width="100%"
                height="90vh"
                name="code-editor"
                value={this.props.editorContent}
                fontSize={parseInt(fontSize)}
            />
        );
    }
}

const mapStateToProps = (state) => {

    return {
        playgroundLanguage: state.playgroundSession.language,
        editorConfig: state.playgroundSession.editorConfig,
        editorContent: state.playgroundSession.editorContent
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateCodeEditor:(content) => dispatch(actions.updateCodeEditorContent(content))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CodeEditor);