import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as playgroundActions from '../../infrastructure/store/actions/playground';

import QuestionContainer from '../Questions/questionContainer';
import PlaygroundCreateForm from './playgroundCreateForm';

import { Button, Paper } from '@material-ui/core';
import { FiCheckCircle } from 'react-icons/fi';
import Alert from '@material-ui/lab/Alert';

class PlaygroundCreateCompontent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            questionIds: [],
            candidateId: null,
            title: ""
        };
    }
    handleCreationForm = (title, candidateId) => {
        this.setState({
            title,
            candidateId,
            step: 1
        })
    }

    handleSelectQuestions = (questionIds) => {
        this.setState({ questionIds })
    }
    handleSelectQuestion = (questionId) => {
        const questionIds = [...this.state.questionIds, questionId];
        this.setState({ questionIds });
    }
    handleDeselectQuestion = (questionId) => {
        const questionIds = this.state.questionIds.filter(question => question !== questionId);
        this.setState({ questionIds });
    }
    handleBack = () => {
        this.setState({ step: this.state.step - 1 })
    }
    createPlayground = () => {
        const { title, candidateId, questionIds } = this.state;
        this.props.createPlayground(title, candidateId, questionIds);
        this.setState({ step: this.state.step + 1 });
    }

    render() {

        const creationSteps = [
            {
                component: <PlaygroundCreateForm step={1} onInput={this.handleCreationForm}></PlaygroundCreateForm>
            },
            {
                component:
                    <div>
                        <Paper elevation={0} className="playground-navigation">
                            <h5>Select questions for a playground</h5>
                            <div>
                                <Button className="mr-3" color="primary" variant="outlined" onClick={this.createPlayground}>Next</Button>
                                <Button color="primary" variant="outlined" onClick={this.handleBack}>Back</Button>
                            </div>
                        </Paper>
                        <QuestionContainer step={2} selectable={true} onSelected={this.handleSelectQuestion} onDeselected={this.handleDeselectQuestion}></QuestionContainer>
                    </div>
            }, {
                component: <div className="playground-created-success">
                    <FiCheckCircle className="playground-created-check"></FiCheckCircle>
                    <h5 className="playground-font-small">Playground Created Successfully</h5>
                    <Link className="mx-auto" to="/dashboard/playgrounds"><Button color="primary" variant="outlined">Confirm</Button></Link>

                </div>
            }
        ]
        return (
            <div>
                {creationSteps[this.state.step].component}
                {this.props.isError &&
                    <Alert className="text-center" severity="error">{this.props.errorMessage}</Alert>}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        isError: state.playground.isError,
        errorMessage: state.playground.errorMessage,
        candidates: state.candidate.candidates,
        isCreated: state.playground.isCreated
    }
}

const mapDispachToProps = (dispatch) => {
    return {
        createPlayground: (title, candidateId, questionIds) => dispatch(playgroundActions.createPlayground(title, candidateId, questionIds))
    }
}
export default connect(mapStateToProps, mapDispachToProps)(PlaygroundCreateCompontent);