import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../infrastructure/store/actions/playgroundSession';
import CodeEditor from '../../components/PlaygroundRoom/codeEditor';
import CodeExecutionPanel from '../../components/PlaygroundRoom/codeExecutionPanel';
import PlaygroundRoomConfigDialog from '../../components/PlaygroundRoom/playgroundRoomConfigDialog';
import PlaygroundRoomNavigation from '../../components/PlaygroundRoom/playRoomNavigation';
import OnlineParticipants from '../../components/PlaygroundRoom/playgroundParticipants';
import { Row, Col } from 'reactstrap';
import { Dialog, DialogActions, DialogTitle, Button } from '@material-ui/core';
import './playgroundRoom.css'
import CandidateInvitationDialog from '../../components/PlaygroundRoom/candidateInvitationDialog';
import PlaygroundDrawer from '../../components/NotesAndQuestions/PlaygroundDrawer';

class PlaygroundRoom extends Component {
    state = {
        isConfigDialogOpen: false,
        isNoteDialogOpen: false,
        isInvitationDialogOpen:false,
        playgroundQuestions:[]
    }


    componentDidMount = () => {

        const { hash } = this.props;
        this.props.wsConnect(hash);
        const playground = this.props.playgrounds.find(pg => pg.hash == hash);
        if(playground){
            this.setState({playgroundQuestions:playground.questions});
        }
        
    }
    componentWillUnmount = () => {
        actions.wsDisconnect();
        this.props.clearState();
    }


    openConfingDialog = () => {
        this.setState({ isConfigDialogOpen: true })
    }

    closeConfigDialog = () => {
        this.setState({ isConfigDialogOpen: false });
    }
    openNoteDialog = () => {
        this.setState({ isNoteDialogOpen: true })
    }
    closeNoteDialog = () => {
        this.setState({ isNoteDialogOpen: false })
    }
    openInvitationDialog = () => {
        this.setState({ isInvitationDialogOpen: true })
    }
    closeInvitationDialog = () => {
        this.setState({ isInvitationDialogOpen: false })
    }


    handleClose = () => {
        this.props.clearState();
    }

    handleLeavePlaygroundRoom = () => {
        actions.wsDisconnect();
        this.props.clearState();
    }

    handleQuestionApplied = (value) => {
        actions.sendEditorContent(value)
        this.props.updateCodeEditorContent(value)
    }


    render() {
        return (<div className="playroom-container">
            <Row>
                <PlaygroundRoomNavigation
                    openConfig={this.openConfingDialog}
                    openNoteDialog={this.openNoteDialog}
                    openInvitationDialog={this.openInvitationDialog}>
                </PlaygroundRoomNavigation>
            </Row>
            <Row>
                <Col lg="7" sm="12" className="p-0">
                    <CodeEditor></CodeEditor>
                </Col>
                <Col lg="5" className="p-0">
                    <CodeExecutionPanel
                        onExecute={this.executeCode}>
                    </CodeExecutionPanel>
                </Col>
            </Row>

            <OnlineParticipants/>
            <CandidateInvitationDialog
            isOpen={this.state.isInvitationDialogOpen}
            onClose={this.closeInvitationDialog}>
            </CandidateInvitationDialog>
            <Dialog open={this.props.isError}>
                <DialogTitle>
                    {this.props.errorMessage}
                </DialogTitle>
                <DialogActions>
                    <Link to="/dashboard/playgrounds">
                        <Button color="primary" variant="outlined">Leave</Button>
                    </Link>
                </DialogActions>
            </Dialog>
            <PlaygroundRoomConfigDialog
                isOpen={this.state.isConfigDialogOpen}
                onClose={this.closeConfigDialog}>
            </PlaygroundRoomConfigDialog>
            <PlaygroundDrawer
            onQuestionApplied={this.handleQuestionApplied}
            hash={this.props.hash}
            isOpen={this.state.isNoteDialogOpen}
            questions={this.state.playgroundQuestions}
            onClose={this.closeNoteDialog}>

            </PlaygroundDrawer>
        </div >);
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        wsConnect: hash => dispatch(actions.wsConnect(hash)),
        clearState: () => dispatch(actions.clearPlaygroundRoomState()),
        changeEditorConfig: (config) => dispatch(actions.changeEditorConfig(config)),
        updateCodeEditorContent: (content) => dispatch(actions.updateCodeEditorContent(content))
    }
}

const mapStateToProps = (state) => {

    return {
        isInvited: state.playgroundSession.isCandidateInvited,
        isError: state.playgroundSession.isError,
        errorMessage: state.playgroundSession.errorMessage,
        playgrounds:state.playground.playgrounds
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundRoom);