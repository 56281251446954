import React, { Component } from 'react';
import { TextField, FormControl, InputLabel, Select } from '@material-ui/core';

class PlaygroundFilterForm extends Component {
    state = {
    }

    handleInput = (event) => {
        event.preventDefault();
        this.setState({ [event.target.id]: event.target.value })
    }

    render() {

        return (<div >
            <form >
                <FormControl>
                    <TextField onChange={this.props.onFilterInput} id="title" variant="outlined" fullWidth label="Title" />
                </FormControl>
                <FormControl variant="outlined" className="ml-2" >
                    <InputLabel >Language</InputLabel>
                    <Select onChange={this.props.onFilterInput} id="languageType" native label="Language">
                        <option aria-label="Select" value="" />
                        {this.props.languages.map(lang =>
                            <option key={lang.id} value={lang.name.toUpperCase()}>{lang.name}</option>)}
                    </Select>
                </FormControl >
                <FormControl onChange={this.props.onFilterInput} className="ml-2" variant="outlined">
                    <InputLabel >Status</InputLabel>
                    <Select id="status" native label="Status">
                        <option aria-label="None" value="" />
                        {this.props.statuses.map(status =>
                            <option key={status} value={status.toUpperCase()}>{status}</option>)}
                    </Select>
                </FormControl>
            </form>
        </div>);
    }
}

export default PlaygroundFilterForm;