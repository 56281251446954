import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../infrastructure/store/actions/playgroundSession';
import {
    DialogContent, Tooltip, Dialog, DialogTitle, DialogActions,
    Switch, TextField, Button, InputAdornment
} from '@material-ui/core';
import { FiCheckCircle, FiCopy, FiUserPlus } from 'react-icons/fi';

class CandidateInvitationDialog extends Component {
    state = {
        shareableLink: true,
        invitationEmail: false
    }

    inviteCandidate = () => {
        this.props.inviteCandidate(this.state);
    }

    copyLinkToClipboard = () => {
        navigator.clipboard.writeText(this.props.invitationLink)
    }
    render() {

        const { isOpen } = this.props;
        const { shareableLink, invitationEmail } = this.state;
        const isInvitationValid = !shareableLink && !invitationEmail;

        return (<Dialog open={isOpen} fullWidth>
            <DialogTitle>
                <FiUserPlus />
                <p className="p-text-small">Invite candidate for an interview</p>
                <div className="float-right">
                    {this.props.isCandidateInvited && <div className="float-left">
                        <FiCheckCircle color="lightgreen" size="20px" />
                        <p className="p-text-small">Candidate invited successfully</p>
                    </div>}
                </div>
            </DialogTitle>
            <DialogContent>
                <hr className="m-0 p-0"></hr>
                <div>
                    <Switch
                        checked={this.state.invitationEmail}
                        color="primary"
                        onChange={() => this.setState({ invitationEmail: !this.state.invitationEmail })}>
                    </Switch>
                    <p className="p-text-small">Send Invitation Email</p>
                </div>
                <div>
                    <Switch
                        checked={this.state.shareableLink}
                        color="primary"
                        onChange={() => this.setState({ shareableLink: !this.state.shareableLink })}
                    ></Switch>
                    <p className="p-text-small">Get Invitation Link</p>
                </div>
                <TextField
                    className="mt-2"
                    variant="outlined"
                    id="input-with-icon-textfield"
                    label="Shareable link"
                    fullWidth
                    disabled
                    value={this.props.invitationLink}
                    InputProps={{
                        startAdornment: (
                            <Tooltip title="Copy to clipboard" arrow>
                                <InputAdornment position="start">
                                    <FiCopy onClick={this.copyLinkToClipboard}
                                        className="copy-clipboard-icon" />
                                </InputAdornment>
                            </Tooltip>
                        ),
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={this.props.onClose}>Back</Button>
                {isInvitationValid ? <Button disabled>Invite</Button>
                    : <Button color="primary" onClick={this.inviteCandidate}>Invite</Button>}
            </DialogActions>
        </Dialog>);
    }
}
const mapStateToProps = (state) => {

    const { invitationLink, isCandidateInvited, isError } = state.playgroundSession;

    return {
        invitationLink,
        isCandidateInvited,
        isError
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        inviteCandidate: (inviteType) => dispatch(actions.inviteCandidate(inviteType))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CandidateInvitationDialog);