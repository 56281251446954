import * as actionTypes from '../actionTypes/candidates';

const initialState = {
    candidates: [],
    isCreated: false,
    isUpdated: false,
    isLoading: false,
    isError: false,
    errorMessage: "",
    metadata: {}
}

const reducer = (state = initialState, action) => {


    if (action.type === actionTypes.BEGIN_CANDIDATE_REQUEST) {
        return beginCandidateRequest(state);
    } else if (action.type === actionTypes.CREATE_CANDIDATE_FINISHED) {
        return candidateCreated(state);
    } else if (action.type === actionTypes.FIND_ALL_CANDIDATES_FINISHED) {
        return candidatesFetched(state, action)
    } else if (action.type === actionTypes.CANDIDATE_REQUEST_FAILED) {
        return requestFailed(state, action)
    } else if (action.type === actionTypes.DELETE_CANDIDATE_FINISHED) {
        return candidateDeleted(state, action)
    } else if (action.type === actionTypes.UPDATE_CANDIDATE_FINISHED) {
        return candidateUpdated(state)
    } else if (action.type === actionTypes.FIND_CANDIDATES_BY_EMAIL_SUCCESSFULL) {
        return candidatesByEmailFetched(state,action)
    } else {
        return state;
    }
}

const beginCandidateRequest = (state) => {
    return {
        ...state,
        isLoading: true,
        isError: false,
        isCreated: false,
        isUpdated: false
    }
}
const candidateCreated = (state) => {
    return {
        ...state,
        isLoading: false,
        isCreated: true
    }
}

const candidatesFetched = (state, action) => {
    return {
        ...state,
        candidates: action.payload.candidates,
        metadata: { pageCount: action.payload.pageCount },
        isLoading: false,
    }
}
const requestFailed = (state, action) => {
    return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload
    }
}

const candidateDeleted = (state, action) => {
    const candidates = state.candidates.filter(candidate => candidate.id !== action.payload);
    return {
        ...state,
        candidates,
        isLoading: false
    }
}

const candidateUpdated = (state) => {
    return {
        ...state,
        isLoading: false,
        isUpdated: true
    }
}
const candidatesByEmailFetched = (state, action) => {
    return {
        ...state,
        candidates: action.payload,
        isError: false,
        isLoading: false,
        metadata: { pageCount: action.payload.length }
    }
}

export default reducer;