import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../infrastructure/store/actions/language';
import * as playgroundActions from '../../../infrastructure/store/actions/playground';
import PlaygroundFilterForm from '../../../components/Playground/playgroundFilterForm';
import PlaygroundTable from '../../../components/Playground/playgroundTable';
import CustomPagination from '../../../components/Commons/Pagination/pagination';
import {
    Button,
    FormControlLabel,
    Checkbox,
    Paper,
} from '@material-ui/core';
import './playgrounds.css';
import { Alert } from '@material-ui/lab';
import PlaygroundDeleteDialog from '../../../components/Playground/playgroundDeleteDialog';

class Playgrounds extends Component {
    state = {
        owned: true,
        title: "",
        languageType: "",
        status: "",
        page: 1,
        size: 5,
        isDeleteDialogOpen: false,
        selectedForDeletion: null
    }

    handlePageChange = (value) => {
        this.setState({ page: value }, () => this.fetchPlaygrounds());
    }

    handleFilterInput = (event) => {
        this.setState({ [event.target.id]: event.target.value }, () => this.fetchPlaygrounds());
    }

    componentDidMount = () => {
        this.props.getAllLanguages();
        this.props.getAllPlaygroundStatuses();
        const { title, status, languageType, page, size } = this.state;
        this.props.getAllOwnedPlaygrounds(title, languageType, status, page, size);
    }

    handleCheck = () => {
        this.setState({ owned: !this.state.owned });
        this.setState({ owned: !this.state.owned }, () => this.fetchPlaygrounds());
    }

    fetchPlaygrounds = () => {
        const { owned, page, size, languageType, status, title } = this.state;
        if (owned) {
            this.props.getAllOwnedPlaygrounds(title, languageType, status, page, size);
        } else {
            this.props.getAllInvitedPlaygrounds(title, languageType, status, page, size);
        }
    }

    openPlaygroundDeleteDialog = (selectedForDeletion) => {
        this.setState({ isDeleteDialogOpen: true, selectedForDeletion })
    }
    closePlaygroundDeleteDialog = () => {
        this.setState({ isDeleteDialogOpen: false, selectedForDeletion: null });
    }
    handleDelete = () => {
        this.props.deletePlayground(this.state.selectedForDeletion.id);
        this.setState({ isDeleteDialogOpen: false });
    }

    render() {

        const numberOfPage = Math.ceil(this.props.pageCount / this.state.size);
    
        return (<div>
            <Paper elevation={0} className="playground-navigation">
                <h4 className="m-0">Playgrounds</h4>
                <Link to="/dashboard/playgrounds/create">
                    <Button color="primary" variant="outlined">Create Playground</Button>
                </Link>
            </Paper>
            <Paper elevation={0} className="p-2 playground-container">
                {
                    this.props.isError && <Alert className="mb-3" severity="error" onClose={() => this.props.clearErrors()}>{this.props.errorMessage}</Alert>
                }
                <div className="playground-navigation-inner">
                    <div></div>
                    <PlaygroundFilterForm
                        languages={this.props.languages}
                        statuses={this.props.statuses}
                        onFilterInput={this.handleFilterInput}>
                    </PlaygroundFilterForm>
                </div>

                <PlaygroundTable
                    isOwned={this.state.owned}
                    onDelete={this.openPlaygroundDeleteDialog}
                    playgrounds={this.props.playgrounds}>
                </PlaygroundTable>
                <div className="playground-navigation-inner">
                    <CustomPagination onSelectedPage={this.handlePageChange} maxPages={numberOfPage}></CustomPagination>
                    <div>
                        <FormControlLabel control={<Checkbox onChange={this.handleCheck} color="primary" name="owned" checked={this.state.owned} />} label="Owned"/>
                        <FormControlLabel control={<Checkbox onChange={this.handleCheck} color="primary" name="invited" checked={!this.state.owned} />} label="Invited" />
                    </div>
                </div>
            </Paper>
            <PlaygroundDeleteDialog
                isOpen={this.state.isDeleteDialogOpen}
                onClose={this.closePlaygroundDeleteDialog}
                onDelete={this.handleDelete}>
            </PlaygroundDeleteDialog>
        </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages.languages,
        statuses: state.playground.statuses,
        isError: state.playground.isError,
        errorMessage: state.playground.errorMessage,
        playgrounds: state.playground.playgrounds,
        pageCount: state.playground.metadata.pageCount
    }
}
const mapDispachToProps = (dispatch) => {
    return {
        getAllLanguages: () => dispatch(actions.getAllLanguages()),
        getAllOwnedPlaygrounds: (title, languageType, status, page, size) => dispatch(playgroundActions.getAllUserOwnedPlaygrounds(title, languageType, status, page, size)),
        getAllInvitedPlaygrounds: (title, languageType, status, page, size) => dispatch(playgroundActions.gettAllUserInvitedPlaygrounds(title, languageType, status, page, size)),
        getAllPlaygroundStatuses: () => dispatch(playgroundActions.getAllPlaygroundStatuses()),
        deletePlayground: (id) => dispatch(playgroundActions.deletePlayground(id)),
        clearErrors: () => dispatch(playgroundActions.clearErrors())
    }
}
export default connect(mapStateToProps, mapDispachToProps)(Playgrounds);