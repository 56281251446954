import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../infrastructure/store/actions/candidates';
import {
    Button,
    TextField,
    CircularProgress,
    Paper
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Form, FormGroup } from 'reactstrap';
import urlParser from 'query-string';

class UpdateCandidateDialog extends Component {

    state = {
        firstName: "",
        lastName: "",
        email: ""
    }

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    componentDidMount = () => {
        const { email, firstName, lastName,id } = urlParser.parse(this.props.location.search);
        this.setState({ email, firstName, lastName,id });
    }

    handleUpdateCandidate = () => {
        this.props.updateCandidate(this.state.id, this.state);
    }

    render() {


        const { lastName, firstName, email } = this.state;
        const areBlank = lastName.length === 0 || firstName.length === 0 || email.length === 0;

        return (
            <Paper elevation={0} className="candidate-form-container">
                <div className="candidate-form">
                    <Form className="wd-50 w-100 mx-auto">
                        <FormGroup>
                            <h3>Update Candidate</h3>
                            <p>Update Candidate Information</p>
                        </FormGroup>
                        <FormGroup>
                            <TextField
                                variant="outlined"
                                onChange={this.handleInput}
                                autoFocus
                                fullWidth
                                id="email"
                                label="Email Address"
                                type="email"
                                value={this.state.email}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextField
                                variant="outlined"
                                onChange={this.handleInput}
                                fullWidth
                                id="firstName"
                                label="First Name"
                                type="text"
                                value={this.state.firstName}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextField
                                variant="outlined"
                                onChange={this.handleInput}
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                type="text"
                                value={this.state.lastName}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Link to="/dashboard/candidates">
                                <Button color="primary">Cancel</Button>
                            </Link>
                            {areBlank ? <Button color="primary" disabled>Update</Button>
                                : <Button onClick={this.handleUpdateCandidate} color="primary">Update</Button>}

                        </FormGroup>
                        <FormGroup>
                            {this.props.isLoading && <CircularProgress className="align-self-center"></CircularProgress>}
                            {this.props.isError && <Alert className="text-center" severity="error">{this.props.errorMessage}</Alert>}
                            {this.props.isUpdated && <Alert className="text-center" severity="success">Candidate successfully updated</Alert>}
                        </FormGroup>
                    </Form>

                </div >
            </Paper>)

    }
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.candidate.isLoading,
        isError: state.candidate.isError,
        errorMessage: state.candidate.errorMessage,
        isUpdated: state.candidate.isUpdated
    }
}
const mapDispachToProps = (dispatch) => {
    return {
        updateCandidate: (id, data) => dispatch(actions.updateCandidate(id, data))
    }
}

export default connect(mapStateToProps, mapDispachToProps)(UpdateCandidateDialog)
