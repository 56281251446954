import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../infrastructure/store/actions/questions';

import { Button, Paper } from '@material-ui/core';
import './questions.css';
import QuestionContainer from '../../../components/Questions/questionContainer';

class Questions extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div>
                <Paper elevation={0} className="playground-navigation">
                    <h4 className="m-0">Questions</h4>
                    <Link to="/dashboard/questions/create">
                        <Button color="primary" variant="outlined">Create Question</Button>
                    </Link>
                </Paper>
                <QuestionContainer></QuestionContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        questions: state.question.questions
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        createQuestion: (title, content, languageId) => dispatch(actions.createQuestion(title, content, languageId)),
        getAllQuestions: () => dispatch(actions.getAllQuestions())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Questions);