import React from 'react'
import {Button} from '@material-ui/core';
import {FiCode} from 'react-icons/fi';
import GenericQuestionCard from '../Questions/questionCard';
export default function QuestionCard(props) {

    const {onSelect,content} = props;

    return (
        <div>
            <GenericQuestionCard
            {...props}
            nav={ <Button startIcon={<FiCode/>} onClick={()=>onSelect(content)} >Select</Button>}>
            </GenericQuestionCard>
        </div>
    )
}
