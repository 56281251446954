import * as actionTypes from '../actionTypes/users';
import { httpSecured } from '../../service/axios';
import * as routes from '../../service/routes';


export const getAllUsersByEmail = (email) => {
    return async dispatch => {
        try {
            dispatch(beginUserRequest())
            const { data } = await httpSecured.get(routes.USERS + "/filter?email=" + email + "&size=200&page=0");
            console.log(data.length,"LENGTH");
            console.log(data);
            dispatch(getAllUsersSuccessfull(data));
        }catch(ex){
            dispatchError(dispatch,ex);
        }
    }
}

const beginUserRequest = () => {
    return {
        type: actionTypes.BEGIN_USER_REQUEST
    }
}

const getAllUsersSuccessfull = (payload) => {
    return {
        type: actionTypes.FETCH_ALL_USERS_SUCCESSFULL,
        payload
    }
}
const userRequestFailed = (payload) => {
    return {
        type: actionTypes.USER_REQUEST_FAILED,
        payload
    }
}

const dispatchError = (dispatch,error) => {
    if(error.response.data.detail){
        dispatch(userRequestFailed(error.response.data.detail))
    }else{
        dispatch(userRequestFailed("Unexpected error ocurred."));
    }
} 