import * as actionTypes from '../actionTypes/playgroundReview';
import * as routes from '../../service/routes';
import {httpSecured} from '../../service/axios';


export const getPlaygroundById = (id) => {
    return async dispatch => {
        dispatch(beginRequest())
        try{
            const {data} = await httpSecured.get(routes.PLAYGROUNDS + "/" + id)
            dispatch(fetchPlayground(data));
        }catch(ex){
            dispatchError(dispatch,ex);
        }
    }
}

const beginRequest = () => {
    return {
        type:actionTypes.PLAYGROUND_REVIEW_BEGIN_REQUEST
    }
}
const fetchPlayground = (payload) => {
    return {
        type:actionTypes.PLAYGROUND_REVIEW_GET_PLAYGROUND_SUCCESSFULL,
        payload
    }
}
const playgroundReviewError = (payload) => {
    return {
        type:actionTypes.PLAYGROUND_REVIEW_REQUEST_ERROR,
        payload
    }
}
const dispatchError = (dispatch,ex) => {
    if(ex.response){
        dispatch(playgroundReviewError(ex.data.response.detail))
    }else{
        dispatch(playgroundReviewError("Unexpected error occurred while fetching playgrounds"));
    }
}