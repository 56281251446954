import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../infrastructure/store/actions/candidates';
import {
    Button,
    TextField,
    CircularProgress,
    Paper,
    Select
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Form, FormGroup } from 'reactstrap';

class CreateCandidateForm extends Component {

    state = {
        firstName: "",
        lastName: "",
        email: "",
        level:""
    }

    candidateLevels = [
         "Intern",
         "Junior",
         "Regular",
         "Senior",
         "Principal"
    ]

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value });
    }

    handleCreateCandidate = () => {
        this.props.createCandidate(this.state);
    }

    render() {

        const { lastName, firstName, email,level } = this.state;
        const areBlank = lastName.length === 0 || firstName.length === 0 || email.length === 0 || level === "";

        return (
            <Paper elevation={0} className="candidate-form-container">
                <Form className="candidate-form">
                    <FormGroup>
                        <h4>Create Candidate</h4>
                        <p>Register a new candidate for an interview</p>
                    </FormGroup>
                    <FormGroup>
                        <TextField
                            required
                            variant="outlined"
                            onChange={this.handleInput}
                            autoFocus
                            fullWidth
                            id="email"
                            label="Email Address"
                            type="email"
                            value={this.state.email}
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextField
                            required
                            variant="outlined"
                            onChange={this.handleInput}
                            fullWidth
                            id="firstName"
                            label="First Name"
                            type="text"
                            value={this.state.firstName}
                        />
                    </FormGroup>
                    <FormGroup>
                        <TextField
                            required
                            variant="outlined"
                            onChange={this.handleInput}
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            type="text"
                            value={this.state.lastName}
                        />
                    </FormGroup>
                    <FormGroup>
                    <FormGroup>
                       <Select onChange={this.handleInput} id="level" native variant="outlined" fullWidth>
                           <option value="" >Candidate Level</option>
                           {this.candidateLevels.map(level => 
                               <option key={level} value={level.toUpperCase()}>{level}</option>
                           )}
                       </Select>
                    </FormGroup>
                        <Link to="/dashboard/candidates">
                            <Button color="primary">Back</Button>
                        </Link>
                        {areBlank ? <Button color="primary" disabled>Register</Button>
                            : <Button onClick={this.handleCreateCandidate} color="primary">Register</Button>}

                    </FormGroup>
                    <FormGroup>
                        {this.props.isLoading && <CircularProgress className="align-self-center"></CircularProgress>}
                        {this.props.isError && <Alert className="text-center" severity="error">{this.props.errorMessage}</Alert>}
                        {this.props.isCreated && <Alert className="text-center" severity="success">Candidate successfully created</Alert>}
                    </FormGroup>
                </Form>
            </Paper>
        )

    }
}

const mapStateToProps = (state) => {

    return {
        isLoading: state.candidate.isLoading,
        isError: state.candidate.isError,
        isCreated: state.candidate.isCreated,
        errorMessage: state.candidate.errorMessage,
    }
}
const mapDispachToProps = (dispatch) => {
    return {
        createCandidate: data => dispatch(actions.createCandidate(data))
    }
}

export default connect(mapStateToProps, mapDispachToProps)(CreateCandidateForm)