import * as actionTypes from '../actionTypes/users';

const initialState = {
    users:[],
    isError:false,
    isLoading:false,
    errorMessage:""
}

const reducer = (state = initialState,action) => {

    if(action.type === actionTypes.BEGIN_USER_REQUEST){
        return{
            ...state,
            isLoading:true,
            isError:false
        }
    }else if(action.type === actionTypes.FETCH_ALL_USERS_SUCCESSFULL){
        return {
            ...state,
            isLoading:false,
            isError:false,
            users:action.payload
        }
    }else if(action.type === actionTypes.USER_REQUEST_FAILED){
        return {
            ...state,
            isLoading:false,
            isError:true,
            errorMessage:action.payload
        }
    }else {
        return state;
    }
}

export default reducer;