import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

//redux-actions
import * as candidateActions from '../../infrastructure/store/actions/candidates';
import * as playgroundActions from '../../infrastructure/store/actions/playground';

//ui components
import {
    Button,
    TextField,
    Paper
} from '@material-ui/core';
import { Form, FormGroup } from 'reactstrap';
import { Autocomplete } from '@material-ui/lab';


class PlaygroundCreateForm extends Component {

    state = {
        title: "",
        selectedCandidate: null,
        searchParam: ""
    }

    handleCreatePlayground = () => {
        const { selectedCandidate, title } = this.state;
        this.props.createPlayground({
            title,
            candidateId: selectedCandidate.id
        });
    }
    handleCandidateAutocomplete = (event, value) => {
        this.setState({ selectedCandidate: value });
    }


    handleTitleInput = (event) => {
        this.setState({ title: event.target.value });
    }

    componentDidMount = () => {
        this.props.getAllCandidatesByEmail(" ");
    }

    validateInput = () => {
        const { selectedCandidate, title } = this.state;
        return selectedCandidate !== null && title.length > 0;
    }
    
    testSomething = () => {
        this.props.onInput(this.state.title,this.state.selectedCandidate.id);
    }
    render() {
        const validated = this.validateInput();
        return (
            <div>
                <Paper elevation={0} className="playground-form-container">
                    <div className="playground-form">
                        <Form>
                            <FormGroup>
                                <p>Set Playground title and candidate</p>
                            </FormGroup>
                            <FormGroup>
                                <TextField
                                    variant="outlined"
                                    onChange={this.handleTitleInput}
                                    autoFocus
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    value={this.state.name}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Autocomplete fullWidth
                                    onChange={this.handleCandidateAutocomplete}
                                    value={this.state.selectedCandidate}
                                    id="candidates"
                                    options={this.props.candidates}
                                    getOptionLabel={(option) => option.email}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Candidates"
                                            variant="outlined" />} />
                            </FormGroup>
                            <FormGroup>
                                <Link to="/dashboard/playgrounds">
                                    <Button color="primary">Back</Button>
                                </Link>
                                {!validated ? <Button color="primary" disabled>Next</Button>
                                    :  <Button onClick={this.testSomething} color="primary">Next</Button>}
                            </FormGroup>
                        </Form>
                    </div >
                </Paper>
            </div>)

    }
}

const mapStateToProps = (state) => {
    return {
        isError: state.playground.isError,
        errorMessage: state.playground.errorMessage,
        candidates: state.candidate.candidates,
        isCreated: state.playground.isCreated
    }
}
const mapDispachToProps = (dispatch) => {
    return {
        getAllCandidatesByEmail: (email) => dispatch(candidateActions.findAllByEmail(email)),
        createPlayground: (data) => dispatch(playgroundActions.createPlayground(data)),
    }
}

export default connect(mapStateToProps, mapDispachToProps)(PlaygroundCreateForm)