import * as actionTypes from '../actionTypes/playground';

const initialState = {
    playgrounds: [],
    statuses: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
    isCreated: false,
    isUpdated: false,
    isShared: false,
    metadata: {
        pageCount: 1
    }
}

const reducer = (state = initialState, action) => {

    if (action.type === actionTypes.BEGIN_PLAYGROUND_REQUEST) {
        return begineRequest(state);
    } else if (action.type === actionTypes.PLAYGROUND_CREATED_SUCCESSFULLY) {
        return playgroundCreated(state)
    } else if (action.type === actionTypes.PLAYGROUND_REQUEST_FAILED) {
        return playgroundRequestError(state, action)
    } else if (action.type === actionTypes.GET_ALL_PLAYGROUNDS_SUCCESSFULL) {
        return playgroundsFetched(state, action)
    } else if (action.type === actionTypes.GET_ALL_STATUSES_SUCCESSFULL) {
        return playgroundStatusesFetched(state, action)
    } else if (action.type === actionTypes.PLAYGROUND_DELETED_SUCCESSFULLY) {
        return playgroundDeleted(state, action)
    } else if (action.type === actionTypes.CLEAR_PLAYGROUND_STATE) {
        return clearPlaygroundState(state)
    } else if (action.type === actionTypes.PLAYGROUND_SHARE_SUCCESSFULL) {
       return playgroundShared(state);
    } else if(action.type === actionTypes.PLAYGROUND_UPDATED_SUCCESSFULLY){
        return playgroundUpdated(state)
    }
    else {
        return state;
    }
}

const begineRequest = (state) => {
    return {
        ...state,
        isLoading: true,
        isError: false,
        isCreated: false,
        isShared: false,
    }
}
const playgroundCreated = (state) => {
    return {
        ...state,
        isLoading: false,
        isError: false,
        isCreated: true
    }
}
const playgroundsFetched = (state, action) => {
    return {
        ...state,
        isLoading: false,
        isError: false,
        isCreated: false,
        playgrounds: action.payload.playgrounds,
        metadata: { pageCount: action.payload.pageCount }
    }
}
const playgroundRequestError = (state, action) => {
    return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload
    }
}

const playgroundStatusesFetched = (state, action) => {
    return {
        ...state,
        statuses: action.payload,
        isError: false,
        isLoading: false,
    }
}

const playgroundUpdated = (state) => {
    return {
        ...state,
        isUpdated:true
    }
}

const playgroundDeleted = (state, action) => {
    const playgrounds = state.playgrounds.filter(pg => pg.id !== action.payload);
    return {
        ...state,
        playgrounds,
        isError: false,
        isLoading: false
    }
}
const clearPlaygroundState = (state) => {
    return {
        ...state,
        isError: false,
        isCreated: false,
        isUpdated: false,
        isShared: false,
        isLoading:false
    }
}

const playgroundShared = (state) => {
    return {
        ...state,
        isShared: true
    }
}

export default reducer;