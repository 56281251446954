import React,{Component} from 'react';
import Pagination from '@material-ui/lab/Pagination';

class CustomPagination extends Component {
  state = {}

  handleChange = (event, value) => {
    this.props.onSelectedPage(value);
  }

  render() {

    return (
      <Pagination onChange={this.handleChange} count={this.props.maxPages} />
    );
  }
}

export default CustomPagination;
