import React, { Component } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Select,
    FormControl,
    InputLabel
} from '@material-ui/core';
import { FiSettings } from 'react-icons/fi';
import { connect } from 'react-redux';
import * as actions from '../../infrastructure/store/actions/playgroundSession';


class PlaygroundRoomConfigDialog extends Component {
    state = {
        selectedLanguageId: 0,
        fontSize: 15,
        theme: "monokai"

    }
    editorThemes = [
        "github",
        "solarized_dark",
        "solarized_light",
        "monokai",
        "terminal"
    ]
    fontSizes = [12, 13, 14, 15, 16, 17, 18];

    componentDidMount = () => {
        this.setState({ ...this.props.editorConfig })
    }

    handleInput = (event) => {
        this.setState({ [event.target.id]: event.target.value })
    }

    handleConfigChange = () => {
        this.props.changeEditorConfig(this.state);
        const { selectedLanguageId } = this.state;
        const { currentLanguage } = this.props;
        if (!currentLanguage && selectedLanguageId > 0) {
            actions.updatePlaygroundLanguage(selectedLanguageId);
        } else if (currentLanguage && currentLanguage.id
            !== selectedLanguageId && selectedLanguageId > 0) {
            actions.updatePlaygroundLanguage(selectedLanguageId);
        }
    }

    getAvailableLanguages = () => {

        const currentLanguage = this.props.currentLanguage;
        const languages = this.props.languages;
        if (currentLanguage) {
            return languages.filter(lang => lang.id !== currentLanguage.id);
        }
        return languages;
    }

    render() {

        const currentLanguage =
            this.props.currentLanguage ? this.props.currentLanguage : { name: "", id: 0 };
        const languages = this.getAvailableLanguages();

        return (<Dialog fullWidth open={this.props.isOpen}>
            <DialogContent>
                <div className="p-3">
                    <FiSettings className="mr-2" />
                Configurations
                <hr></hr>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Language</InputLabel>
                        <Select
                            displayEmpty
                            onChange={this.handleInput}
                            id="selectedLanguageId"
                            native
                            label="Language">
                            <option value={currentLanguage.id}>{currentLanguage.name}</option>
                            {languages.map(lang =>
                                <option key={lang.id} value={lang.id}>{lang.name}</option>)}
                        </Select>
                    </FormControl>
                </div>
                <div className="p-3">
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Font Size</InputLabel>
                        <Select
                            onChange={this.handleInput}
                            id="fontSize"
                            label="Font Size"
                            native
                            value={this.state.fontSize}>
                            {this.fontSizes.map(size => <option key={size} value={size}>{size + "px"}</option>)}
                        </Select>
                    </FormControl>
                </div>
                <div className="p-3">
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>Theme</InputLabel>
                        <Select
                            id="theme"
                            onChange={this.handleInput}
                            label="Theme"
                            native
                            value={this.state.theme}>

                            {this.editorThemes.map(theme =>
                                <option key={theme} value={theme}>{theme}</option>
                            )}
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.props.onClose} color="primary">Back</Button>
                <Button onClick={this.handleConfigChange} variant="outlined" color="primary">Apply</Button>
            </DialogActions>
        </Dialog>);
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages.languages,
        editorConfig: state.playgroundSession.editorConfig,
        currentLanguage: state.playgroundSession.language
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        changeEditorConfig: (config) => dispatch(actions.changeEditorConfig(config))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundRoomConfigDialog);