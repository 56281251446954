
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FiUsers, FiAirplay, FiCode} from 'react-icons/fi';
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import './sidebar.css';
import logo from '../../assets/images/logo-white.png'

class Sidebar extends Component {
    state = {
        sideBarToggled: false
    }

    openSideBar = () => {
        this.setState({ sideBarToggled: true })
    }
    closeSideBar = () => {
        this.setState({ sideBarToggled: false })
    }

    dashboardItems = [
        {
            label: "Playgrounds",
            link: "/dashboard/playgrounds",
            icon: <FiAirplay className="sidebar-icon" />
        },
        {
            label: "Candidates",
            link: "/dashboard/candidates",
            icon: <FiUsers className="sidebar-icon" />,
        },{
            label: "Questions",
            link: "/dashboard/questions",
            icon: <FiCode className="sidebar-icon"/>
        }
    ]
    render() {
        return (<div>
            
            <div className="sidebar">
                <List>
                    <div className="logo-container">
                        <img width="100px" height="30px" src={logo} alt="Codish"></img>
                    </div>
                    <Divider></Divider>
                    {this.dashboardItems.map(item => {
                        const isActive = window.location.pathname.startsWith(item.link)
                            ? "sidebar-item-active" : ""
                        return (
                            <Link className="sidebar-item" to={item.link} key={item.label}>
                                <div className={isActive}>
                                    <ListItem button>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText className="sidebar-item-text">{item.label}</ListItemText>
                                    </ListItem>
                                </div>
                            </Link>
                        );
                    })}
                </List>
            </div>
            {this.props.isToggled && <div className="sidebar-toggled-on">
                <List>
                    {this.dashboardItems.map(item => {
                        const isActive = window.location.pathname.startsWith(item.link)
                            ? "sidebar-item-active" : ""
                        return (
                            <Link onClick={() => this.props.toggle(false)} className="sidebar-item" to={item.link} key={item.label}>
                                <div className={isActive}>
                                    <ListItem button>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText className="sidebar-item-text">{item.label}</ListItemText>
                                    </ListItem>
                                </div>
                            </Link>
                        );
                    })}
                </List>
            </div>}
        </div>);
    }
}

export default Sidebar;