import React, { Component } from 'react';
import urlParser from 'query-string';
import * as actions from '../../infrastructure/store/actions/authentication';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { Redirect } from 'react-router';

class CandidateAuthenticationRoute extends Component {
    state = {
        authenticated: false,
        hash: ""
    }

    componentDidMount = () => {
        const { auth: token, playground: hash } = urlParser.parse(this.props.location.search);
        this.props.authenticateCandidate(token);
        this.setState({ authenticated: true, hash })
    }

    render() {
        return this.state.authenticated ? <Redirect to={"/playground-room/" + this.state.hash}></Redirect>
            : <CircularProgress></CircularProgress>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        authenticateCandidate: (token) => dispatch(actions.authenticateCandidate(token))
    }
}

const mapStateToProps = (state) => {
    return {
        isError: state.auth.isError,
        errorMessage: state.auth.errorMessage
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateAuthenticationRoute);