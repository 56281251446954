
import React, { Component } from 'react';
import { FiUser, FiUserPlus, FiUserMinus } from 'react-icons/fi';
import { connect } from 'react-redux';
import { Tooltip, Chip, Snackbar } from '@material-ui/core';
import * as actions from '../../infrastructure/store/actions/playgroundSession';

class PlaygroundParticipants extends Component {
    state = {}

    handleClose = () => {
        this.props.clearState();
    }

    render() {
        return (<div className="online-participants-container">
            {this.props.interviewers.map(interviewer => {
                return (
                    <Tooltip key={interviewer.id} title="Interviewer" arrow>
                        <Chip
                            className="online-participant-card"
                            icon={<FiUser size="15px" color="blue" />}
                            label={interviewer.firstName + " " + interviewer.lastName}
                            variant="outlined">
                        </Chip>
                    </Tooltip>
                )
            })}
            {this.props.candidate &&
                <Tooltip title="Candidate" arrow>
                    <Chip
                        className="online-participant-card"
                        icon={<FiUser size="15px" color="blue" />}
                        label={this.props.candidate.firstName + " " + this.props.candidate.lastName}
                        variant="outlined">
                    </Chip>
                </Tooltip>}
            <Snackbar autoHideDuration={5000} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }} open={this.props.hasJoined} onClose={this.handleClose}>
                <div className="playground-room-snackbar" ><FiUserPlus size="18px" className="mr-2" color="white"></FiUserPlus>
                    {this.props.participantName + " has joined!"}</div>
            </Snackbar>
            <Snackbar autoHideDuration={5000} anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }} open={this.props.hasLeft} onClose={this.handleClose}>
                <div className="playground-room-snackbar" ><FiUserMinus size="18px" className="mr-2" color="white"></FiUserMinus>
                    Participant has left!</div>
            </Snackbar>
        </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        interviewers: state.playgroundSession.interviewers,
        candidate: state.playgroundSession.candidate,
        hasJoined: state.playgroundSession.hasJoined,
        hasLeft: state.playgroundSession.hasLeft,
        participantName: state.playgroundSession.participantName
    }
}

const mapDispachToProps = (dispatch) => {
    return {
        clearState: () => dispatch(actions.clearPlaygroundRoomState()),
    }
}

export default connect(mapStateToProps, mapDispachToProps)(PlaygroundParticipants);