import * as actionTypes from '../actionTypes/authentication';
import * as routes from '../../service/routes';
import * as userTypes from '../../service/userType';
import { http } from '../../service/axios';
import jwtDecode from 'jwt-decode';

export const authenticate = (loginCredentials) => {

    return async dispatch => {
        dispatch(authenticationAttempted());
        try {
            const clientId = process.env.REACT_APP_CLIENT_ID;
            const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
            const basicAuthHeader = `Basic ${btoa(clientId + ":" + clientSecret)}`

            // const form = new FormData();
            // form.append('username',loginCredentials.username);
            // form.append('password',loginCredentials.password);
            // form.append('grant_type','password');

            const loginRoute = routes.LOGIN_ROUTE + `?username=${loginCredentials.username}&password=${escape(loginCredentials.password)}&grant_type=password`;
    
            const response = await http.post(loginRoute, {},{
                headers:{
                    Authorization:basicAuthHeader,
                }
            });

            const jwt = response.data.access_token;
            const decoded = jwtDecode(jwt);
            const currentUser = getUserDataFromToken(decoded);
            const interviewerRole = currentUser.userInfo.roles.find(role => role === 'ROLE_INTERVIEWER');
            if(!interviewerRole){
                dispatch(userLoginFailed("Interviewer role required for access."));
                return;
            }
            
            localStorage.setItem("token", jwt);
            dispatch(userLoginSuccessfull({
                currentUser,
                userType:userTypes.USER
            }));
        } catch (ex) {
            console.log(ex);
            if (ex.response) {
                dispatch(userLoginFailed(ex.response.data.message));
            } else {
                dispatch(userLoginFailed("Unexped error ocurred. Try again later."));
            }
        }
    }
}

export const logout = () => {
    localStorage.removeItem("token");
    return {
        type: actionTypes.LOGOUT
    }
}

export const verifyAuthentication = () => {

    return dispatch => {
        dispatch(authenticationAttempted())
        const token = localStorage.getItem("token");
        const candidateToken = localStorage.getItem("candidate-token")
        if (token) {
            const payload = verifyTokenExpiration(dispatch, token);
            if(payload){
                dispatch(authenticationSuccessful({
                    currentUser: getUserDataFromToken(payload),
                    userType: userTypes.USER
                }))
            }
        } else if (candidateToken !== null) {
            const payload = verifyTokenExpiration(dispatch, candidateToken);
            if(payload){
                dispatch(authenticationSuccessful({
                    currentUser: payload,
                    userType: userTypes.CANDIDATE
                }))
            }
        } else {
            dispatch(authenticationFailed("Authentication token not present."))
        }
    }
}

const verifyTokenExpiration = (dispatch, token) => {
    try {
        const payload = jwtDecode(token);
        const now = Date.now() / 1000;
        if (payload.exp > now) {
            return payload;
        } else {
            dispatch(authenticationFailed("Session expired."))
        }
    } catch (ex) {
        dispatch(authenticationFailed("Invalid token supplied."));
    }
}

export const authenticateCandidate = (token) => {
    
    return dispatch => {
        try {
            const payload = jwtDecode(token);
            localStorage.setItem("candidate-token",token);
            localStorage.removeItem("token");
            dispatch(authenticationSuccessful({
                payload,
                userType:userTypes.CANDIDATE
            }))
        }catch(ex){
            dispatch(authenticationFailed("Invalid token specified."));
        }
    }
}
const authenticationAttempted = () => {

    return {
        type: actionTypes.AUTH_ATTEMPT,
        isLoading: true
    }
}
const authenticationSuccessful = (payload) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        payload: payload,
        isLoading: false,
    }
}
const authenticationFailed = (error) => {

    return {
        type: actionTypes.AUTH_FAILED,
        payload: error,
        isLoading: false,
        isError: true
    }
}

const userLoginSuccessfull = (payload) => {
    return {
        type:actionTypes.USER_LOGIN_SUCCESSFULL,
        payload
    }
}

const userLoginFailed = (payload) => {
    return{
        type:actionTypes.USER_LOGIN_FAILED,
        payload
    }
}

const getUserDataFromToken = (decodedToken) => {
    return {
        userInfo:{
            firstName:decodedToken.first_name,
            lastName:decodedToken.last_name,
            id:decodedToken.id,
            email:decodedToken.email,
            roles:decodedToken.authorities
        }
    }
}



