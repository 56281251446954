import axios from 'axios';

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

export const httpSecured = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

httpSecured.interceptors.request.use(
  request => {
    const token = localStorage.getItem("token");
    request.headers.Authorization = `Bearer ${token}`
    return request;
  },
  error => {
    if(error.response.status === 401){
      window.location = "/login";
      return;
    }
    return Promise.reject(error);
  }
);
