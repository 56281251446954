import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import CandidateTable from '../../../components/Candidate/candidateTable';
import DeleteCandidateDialog from '../../../components/Candidate/deleteCandidateDialog';

import './candidates.css';
import { Button, Paper, TextField } from '@material-ui/core';
import * as actions from '../../../infrastructure/store/actions/candidates';
import CustomPagination from '../../../components/Commons/Pagination/pagination';
import { Alert } from '@material-ui/lab';


class Candidates extends Component {
    state = {
        isDeleteDialogOpen: false,
        selectedPage: 1,
        perPage: 5,
        selectedCandidate: {}
    }

    openDeleteDialog = (candidate) => {
        this.setState({ isDeleteDialogOpen: true, selectedCandidate: candidate })
    }

    closeDeleteDialog = () => {
        this.setState({ isDeleteDialogOpen: false })
    }

    handleDeleteCandidate = () => {
        this.props.deleteCandidate(this.state.selectedCandidate.id);
        this.setState({ isDeleteDialogOpen: false });
    }

    componentDidMount = () => {
        this.props.getAllCandidatesByUserId(this.state.selectedPage, this.state.perPage);
    }

    handleFilterByEmail = (event) => {
        this.props.findAllWhereEmailLike(event.target.value)
        if (event.target.value === "") {
            this.props.getAllCandidatesByUserId(this.state.selectedPage, this.state.perPage);
        }
    }

    handlePageChange = (value) => {
        this.props.getAllCandidatesByUserId(value, this.state.perPage);
        this.setState({ currentPage: value });
    }

    render() {

        const numberOfPages = Math.ceil(this.props.metadata.pageCount / this.state.perPage);

        return (<div>
            <Paper elevation={0} className="candidate-nav">
                <h4>Candidates</h4>
                <Link to="/dashboard/candidates/create">
                    <Button variant="outlined" color="primary">Create Candidate</Button>
                </Link>
            </Paper>
            <Paper elevation={0} className="candidate-table-container">
                { this.props.isError && <Alert onClose severity="error">{this.props.errorMessage}</Alert>}
                <TextField id="filterEmail" className="candidate-filter-input" variant="outlined" onChange={this.handleFilterByEmail} label="Search" />
                <CandidateTable
                    onDelete={this.openDeleteDialog} />
                <div className="candidate-nav">
                    <CustomPagination onSelectedPage={this.handlePageChange} maxPages={numberOfPages} />
                </div>
            </Paper>
            <DeleteCandidateDialog
                isOpen={this.state.isDeleteDialogOpen}
                onClose={this.closeDeleteDialog}
                onDelete={this.handleDeleteCandidate}
            ></DeleteCandidateDialog>
        </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.candidate.isLoading,
        isError: state.candidate.isError,
        errorMessage: state.candidate.errorMessage,
        candidates: state.candidate.candidates,
        metadata: state.candidate.metadata
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllCandidatesByUserId: (page, size) => dispatch(actions.findAllByUserId(page, size)),
        deleteCandidate: id => dispatch(actions.deleteCandidate(id)),
        findAllWhereEmailLike: email => dispatch(actions.findAllByEmail(email))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);