
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Redirect } from 'react-router';
import logoImage from '../../assets/images/logo-white.png'
import { FiCommand, FiBookOpen, FiRefreshCw } from 'react-icons/fi';
import './landingPage.css';

class LandingPage extends Component {
    state = {}
    render() {

        if (this.props.isAuthenticated) {
            return <Redirect to="/dashboard/playgrounds"></Redirect>
        }

        const codish = "</Codish>"
        return (<div>
            <Row className="landing-page-navigation">
                <img width="100px" height="30px" src={logoImage} alt=""></img>
            </Row>
            <Row className="landing-page-banner-row">
                <Col xl="12" className="landing-page-banner-container">
                    <h2>Solution for your remote interviews</h2>
                    <h5>Code in real-time with <b>{codish}</b></h5>
                    <a href="/login">
                        <button className="landing-page-action-button">Begin</button>
                    </a>
                </Col>
                <Col>
                    <Row className="landing-page-icon-row">
                        <Col className="landing-page-icon-container" xl="4">
                            <FiCommand size="25px" color="white" className="mb-2"></FiCommand>
                            <p>Create and manage</p>
                            <p>playgrounds</p>
                            <div className="blue-separator"></div>
                        </Col>
                        <Col className="landing-page-icon-container" xl="4">
                            <FiBookOpen size="25px" color="white" className="mb-2" />
                            <p>Create refined questions</p>
                            <p>and assignments</p>
                            <div className="blue-separator"></div>
                        </Col>
                        <Col className="landing-page-icon-container" xl="4">
                            <FiRefreshCw size="25px" color="white" className="mb-2" />
                            <p className="">Revisit playgrounds for</p>
                            <p>later evaluation</p>
                            <div className="blue-separator"></div>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated
    }
}
export default connect(mapStateToProps, null)(LandingPage);