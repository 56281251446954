import * as actionTypes from '../actionTypes/languages';

const initialState = {
    languages: [],
    isLoading: false,
    isError: false,
    errorMessage: ""
}
const reducer = (state = initialState, action) => {

    if (action.type === actionTypes.BEGIN_LANGUAGE_REQUEST) {
        return beginRequest(state)
    } else if (action.type === actionTypes.LANGUAGES_FETCHED_SUCCESSFULLY) {
       return languagesFetched(state,action)
    } else if (action.type === actionTypes.LANGUAGES_REQUEST_FAILED) {
      return languagesRequestFailed(state,action);
    } else {
        return state;
    }
}

const beginRequest = (state) => {
    return {
        ...state,
        isLoading: true,
        isError: false
    }
}

const languagesFetched = (state, action) => {
    return {
        ...state,
        isLoading: false,
        isError: false,
        languages: action.payload
    }
}

const languagesRequestFailed = (state, action) => {
    return {
        ...state,
        isError: true,
        errorMessage: action.payload
    }
}

export default reducer;