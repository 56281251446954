import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import PlaygroundRoom from '../../../containers/PlaygroundRoom/playgroundRoom';

class PlaygroundRoomRoute extends Component {
    state = {}
    render() {

        const hash = this.props.match.params.hash;
        return this.props.isAuthenticated ?
            <PlaygroundRoom hash={hash}></PlaygroundRoom> : <Redirect to="/login"></Redirect>
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
    }
}

export default connect(mapStateToProps, null)(PlaygroundRoomRoute);