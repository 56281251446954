import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../infrastructure/store/actions/notes';

import { Drawer, Tabs, Tab, TextareaAutosize, Button } from '@material-ui/core';
import { FiBook, FiFileText } from 'react-icons/fi';
import QuestionCard from './questionCard';
import './playgroundDrawer.css';
import ConfirmationDialog from '../Commons/ConfirmationDialog';

class PlaygroundDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            noteValue: "",
            isSelectQuestionDialogOpen:false
        };
    }

    handleChange = () => {
        if (this.state.value === 1) {
            this.setState({ value: 0 });
        } else {
            this.setState({ value: 1 })
        }
    }

    openSelectQuestionConfirmDialog = (questionContent) => {
        this.setState({
            isSelectQuestionDialogOpen:true,
            selectedQuestionContent:questionContent})
    }

    selectQuestion = () => {
        const {selectedQuestionContent} = this.state;
        this.props.onQuestionApplied(selectedQuestionContent);
        this.setState({
            isSelectQuestionDialogOpen:false,
            selectedQuestionContent:""
        })
    }

    componentDidMount = () => {
        this.props.getAllNotes(this.props.hash);
    }

    handleTextInput = (event) => {
        this.setState({ noteValue: event.target.value })
    }

    createNote = () => {
        if (this.state.noteValue.length < 5) {
            this.setState({ errorMessage: true })
        } else {
            this.props.addNote(this.props.hash, this.state.noteValue);
        }
    }

    render() {

        const note = <div className="m-3">
            <p>Create a note</p>
            <TextareaAutosize
                value={this.state.content}
                className="playground-note-textarea"
                rowsMin={6}
                onChange={this.handleTextInput}>
            </TextareaAutosize>
            {this.state.errorMessage && <p className="error-msg">Note must be greather than 5 characters.</p>}
            <Button onClick={this.createNote} variant="outlined" color="primary">Add Note</Button>
            <div className="question-notes-drawer m-3">
                    {this.props.notes.map(note => {
                        return <div key={note.id} className="note-container mt-2 mb-2">
                            <p>{note.value}</p>
                        </div>
                    })}
                </div>
        </div>

        const question = <div className="m-3">
            <div className="question-notes-drawer">
                {this.props.questions.map(qs => {
                    return (<div>
                        <QuestionCard 
                        {...qs} 
                        key={qs.id}
                        onSelect={this.openSelectQuestionConfirmDialog}>
                        </QuestionCard>
                    
                    </div>)
                })}
            </div>
        </div>

        const content = this.state.value === 1 ? note : question;

        return (
            <Drawer open={this.props.isOpen}
                onClose={this.props.onClose}
                >
                <div className="question-notes-drawer">
                    <div>
                        <Tabs
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                            aria-label="icon label tabs example"
                            value={this.state.value}
                            onChange={this.handleChange}
                        >
                            <Tab value={1} icon={<FiBook size="20px" />} label="Notes" />
                            <Tab value={0} icon={<FiFileText size="20px" />} label="Questions" />
                        </Tabs>
                    </div>
                </div>
                {content}
                <ConfirmationDialog
                 isOpen={this.state.isSelectQuestionDialogOpen} 
                 title="Select Question" 
                 content="Updating code editor with question will remove previous code"
                 onCancel={()=>this.setState({isSelectQuestionDialogOpen:false})}
                 onConfirm={this.selectQuestion}
                 >
                 

                </ConfirmationDialog>
            </Drawer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notes: state.note.notes
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNote: (hash, content) => dispatch(actions.createNote(hash, content)),
        getAllNotes: (hash) => dispatch(actions.getAllNotes(hash))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundDrawer);