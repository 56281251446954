import React, { Component } from 'react';
import {
    Button,
    TextField,
    Paper
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Form, FormGroup } from 'reactstrap';
import { Autocomplete } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import urlParser from 'query-string';
import * as candidateActions from '../../infrastructure/store/actions/candidates';
import * as playgroundActions from '../../infrastructure/store/actions/playground';

class PlaygroundUpdateForm extends Component {
    state = {
        selectedCandidate: {
            email: ""
        },
        title: "",
        languageId: null,
        languageName: ""
    }

    handleCandidateAutocomplete = (event, value) => {
        this.setState({ selectedCandidate: value });
    }

    handleSelectLanguage = (event) => {
        this.setState({ languageId: event.target.value });
    }

    handleTitleInput = (event) => {
        this.setState({title:event.target.value});
    }

    handleUpdatePlayground = () => {
        const {title,languageId,selectedCandidate,id} = this.state;
        this.props.updatePlayground(id,{
            title,
            languageId,
            candidateId:selectedCandidate.id
        })
    }

    componentDidMount = () => {

        const { id, title, candidateId, candidateEmail}
            = urlParser.parse(this.props.location.search);

        this.setState({
            id,
            title,
            selectedCandidate: {
                id: candidateId,
                email: candidateEmail
            }
        });
        this.props.getAllCandidatesByEmail("");
    }

    componentWillUnmount = () => {
        this.props.clearState();
    }

    render() {

        const {title,selectedCandidate} = this.state;
        const validated = title.length > 1 && selectedCandidate != null;;
      
        return (<Paper elevation={0} className="playground-form-container">
            <div className="playground-form">
                <Form>
                    <FormGroup>
                        <h4>Update a playground</h4>
                    </FormGroup>
                    <FormGroup>
                        <TextField
                            variant="outlined"
                            onChange={this.handleTitleInput}
                            autoFocus
                            fullWidth
                            id="title"
                            label="Playground Name"
                            value={this.state.title}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Autocomplete fullWidth
                            onChange={this.handleCandidateAutocomplete}
                            value={this.state.selectedCandidate}
                            id="candidates"
                            options={this.props.candidates}
                            getOptionLabel={(option) => option.email}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Candidates"
                                    variant="outlined" />} />
                    </FormGroup>
                    <FormGroup>
                        <Link to="/dashboard/playgrounds">
                            <Button color="primary">Back</Button>
                        </Link>
                        {!validated ? <Button color="primary" disabled>Update</Button>
                            : <Button onClick={this.handleUpdatePlayground} color="primary">Update</Button>}
                    </FormGroup>
                    <FormGroup>
                        {this.props.isUpdated &&
                            <Alert className="text-center" severity="success">Playground updated successfully!</Alert>}
                    </FormGroup>
                </Form>
            </div >
        </Paper>);
    }
}

const mapStateToProps = (state) => {
    return {
        isError: state.playground.isError,
        errorMessage: state.playground.errorMessage,
        candidates: state.candidate.candidates,
        isUpdated: state.playground.isUpdated
    }
}

const mapDispachToProps = (dispatch) => {
    return {
        getAllCandidatesByEmail: (email) => dispatch(candidateActions.findAllByEmail(email)),
        updatePlayground:(id,data) => dispatch(playgroundActions.updatePlayground(id,data)),
        clearState:() => dispatch(playgroundActions.clearErrors())
    }
}

export default connect(mapStateToProps, mapDispachToProps)(PlaygroundUpdateForm);