
import React, { Component } from 'react';
import './toolbar.css';
import { FiUser } from 'react-icons/fi';
import { MenuItem, MenuList, Paper, Popper, IconButton, Divider } from '@material-ui/core';
import { connect } from 'react-redux';
import * as actions from '../../../infrastructure/store/actions/authentication';
import { FiMenu } from 'react-icons/fi';

class Toolbar extends Component {
    state = {
        anchor: null,
        isOpen: false,
        sideBarToggled: false
    }

    ref = React.createRef();
    onClick = (event) => {

        if (this.state.anchor == null) {
            this.setState({ anchor: event.target })
        }

        this.setState({ isOpen: !this.state.isOpen })
    }

    render() {

        return (
            <Paper elevation={0}>
                <div className="toolbar-container">
                    <div>
                        <IconButton onClick={() => this.props.toggle(true)} className="toolbar-menu-item">
                            <FiMenu size="20px"></FiMenu>
                        </IconButton>
                    </div>
                    <div ref={this.ref} className="toolbar-user-navigation">
                        Welcome, {this.props.currentUser.userInfo.firstName}
                        <IconButton onClick={this.onClick}>
                            <FiUser size="20px"></FiUser>
                        </IconButton>
                    </div>
                    <Popper open={this.state.isOpen} anchorEl={this.state.anchor}>
                        <Paper>
                            <MenuList autoFocusItem={true}>
                                <MenuItem disabled>Profile</MenuItem>
                                <Divider></Divider>
                                <MenuItem onClick={() => this.props.logout()}>Logout</MenuItem>
                            </MenuList>
                        </Paper>
                    </Popper>
                </div>
            </Paper>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(actions.logout())
    }
}
const mapStateToProps = (state) => {
    return {
        currentUser: state.auth.currentUser
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);