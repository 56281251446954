import * as actionTypes from '../actionTypes/playgroundSession';

const initialState = {
    isConnected: false,
    interviewers: [],
    candidate: null,
    principal: null,
    isLoading: false,
    isError: false,
    errorMessage: "",
    editorContent: "",
    executionOutput: "",
    language: null,
    invitationLink:"",
    isCandidateInvited: false,
    hasJoined: false,
    hasLeft: false,
    hasEnded: false,
    participantName: "",
    editorConfig: {
        theme: "monokai",
        fontSize: 15
    }
}
const reducer = (state = initialState, action) => {

    if (action.type === actionTypes.BEGIN_PLAYGROUND_ROOM_REQUEST) {
        return startLoading(state);
    } else if (action.type === actionTypes.PLAYGROUND_ROOM_CONNECT_SUCCESSFULL) {
        return connectToWebsocket(state, action)
    } else if (action.type === actionTypes.PLAYGROUND_ROOM_FETCH_INITIAL_DATA_SUCCESSFULL) {
        return getCurrentSessionState(state, action);
    } else if (action.type === actionTypes.PLAYGROUND_ROOM_CODE_EDITOR_CONTENT_RECEIVED) {
        return changeEditorContent(state, action);
    } else if (action.type === actionTypes.PLAYGROUND_ROOM_EXEC_OUTPUT_REQUESTED) {
        return startLoading(state);
    } else if (action.type === actionTypes.PLAYGROUND_ROOM_EXEC_OUTPUT_RECEIVED) {
        return executionOutputReceived(state, action);
    } else if (action.type === actionTypes.CANDIDATE_INVITED_SUCCESSFULLY) {
        return candidateInvited(state,action);
    } else if (action.type === actionTypes.PLAYGROUND_ROOM_REQUEST_FAILED) {
        return handleError(state, action);
    } else if (action.type === actionTypes.CLEAR_PLAYGROUND_ROOM_STATE) {
        return clearState(state);
    } else if (action.type === actionTypes.CHANGE_EDITOR_CONFIG) {
        return changeCodeEditorConfig(state, action)
    } else if (action.type === actionTypes.PLAYGROUND_ROOM_PARTICIPANT_JOINED) {
        return participantJoined(state, action);
    } else if (action.type === actionTypes.PLAYGROUND_ROOM_PARTICIPANT_LEFT) {
        return participantLeft(state, action);
    } else if (action.type === actionTypes.PLAYGROUND_ROOM_ENDED_SUCCESSFULLY) {
        return endPlaygroundRoom(state);
    } else if (action.type === actionTypes.PLAYGROUND_ROOM_LANGUAGE_CHANGE_RECEIVED) {
        return updatePlaygroundLanguage(state,action)
    } else if (action.type === actionTypes.PLAYGROUND_ROOM_UPDATE_CODE_EDITOR){
        return updateCodeEditor(state,action)
    } else {
        return state;
    }
}

const getCurrentSessionState = (state, action) => {

    const { editorContent, language, interviewers, principal, candidate } = action.payload;
    //removes duplicated same online interviewers, when interviewer logs in into a playground multiple times
    const uniqueInterviewersArray = Array.from(new Set([...interviewers]));
    const codeEditorContent = editorContent === null ? "" : editorContent;

    return {
        ...state,
        editorContent:codeEditorContent,
        language,
        interviewers: uniqueInterviewersArray,
        principal,
        candidate,
        isLoading: false,
    }
}

const clearState = (state) => {

    return {
        ...state,
        isError: false,
        errorMessage: "",
        isCandidateInvited: false,
        hasJoined: false,
        hasLeft: false,
        hasEnded: false,
        invitationLink:""
    }
}


const connectToWebsocket = (state) => {
    return { ...state, isConnected: true, isLoading: false, };
}

const changeEditorContent = (state, action) => {
    return { ...state, editorContent: action.payload, isLoading: false, }
}

const startLoading = (state) => {
    return { ...state, isLoading: true, executionOutput: "" };
}

const executionOutputReceived = (state, action) => {
    return {
        ...state,
        isLoading: false,
        executionOutput: action.payload
    }
}

const candidateInvited = (state,action) => {
    return {
        ...state,
        invitationLink:action.payload,
        isCandidateInvited: true,
        isLoading: false,
    }
}

const changeCodeEditorConfig = (state, action) => {
    return {
        ...state,
        editorConfig: action.payload,
    }
}

const participantJoined = (state, action) => {
    const participant = action.payload;
    const participantName = participant.firstName + " " + participant.lastName;
    if (participant.participantType === "USER") {

        const isInterviewerAlreadyOnline
            = state.interviewers.filter(interviewer => interviewer.id === action.payload.id);
        const interviewers = isInterviewerAlreadyOnline.length
            === 0 ? [...state.interviewers, action.payload] : state.interviewers;

        return {
            ...state,
            hasJoined: true,
            hasLeft: false,
            interviewers,
            participantName
        }
    } else {
        return {
            ...state,
            hasJoined: true,
            candidate: action.payload,
            participantName,
            hasLeft: false,
        }
    }
}

const participantLeft = (state, action) => {

    const participant = action.payload;
    if (participant.participantType === "USER") {
        const interviewers = state.interviewers.filter(interviewer => interviewer.id !== participant.id);
        return { ...state, interviewers, hasLeft: true };
    } else {
        return { ...state, candidate: null, hasLeft: true, hasJoined: false }
    }
}

const handleError = (state, action) => {
    return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: action.payload
    }
}

const endPlaygroundRoom = (state) => {
    return { ...state, hasEnded: true };
}

const updatePlaygroundLanguage = (state,action) => {
    return {
        ...state,
        language:action.payload,
        editorContent:action.payload.template
    }
}

const updateCodeEditor = (state,action) =>{
    return {
        ...state,
        editorContent:action.payload
    }
}
export default reducer;