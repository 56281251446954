import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../infrastructure/store/actions/playgroundReview';
import { CircularProgress } from '@material-ui/core';
import PlaygroundReviewNavigation from '../../components/PlaygroundReviewRoom/playgroundReviewNavigation';
import './playgroundReviewRoom.css';
import CodeEditor from '../../components/PlaygroundReviewRoom/codeEditor';
import PlaygroundReviewParticipants from '../../components/PlaygroundReviewRoom/playgroundReviewParticipants';
import NotesDrawer from '../../components/PlaygroundReviewRoom/notesDrawer';

class PlaygroundReviewRoom extends Component {
    state = {
        isNoteDialogOpen:false,
    }

    toggleNoteDialog = () => {
        this.setState({isNoteDialogOpen:!this.state.isNoteDialogOpen})
    }

    componentDidMount = () => {
        this.props.getPlaygroundById(this.props.match.params.id);
    }

    render() {

        if (!this.props.playground) {
            return <CircularProgress></CircularProgress>
        }
        return (<div>
            <PlaygroundReviewNavigation
                {...this.props.playground}
                openNoteDialog={this.toggleNoteDialog}>
            </PlaygroundReviewNavigation>
            <CodeEditor
                {...this.props.playground}>
            </CodeEditor>
            <PlaygroundReviewParticipants
                {...this.props.playground}>
            </PlaygroundReviewParticipants>
            <NotesDrawer notes={this.props.playground.notes} isOpen={this.state.isNoteDialogOpen} onClose={this.toggleNoteDialog}></NotesDrawer>
        </div>);

    }
}

const mapStateToProps = (state) => {
    return {
        playground: state.playgroundReview.playground,
        isLoading: state.playgroundReview.isLoading
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getPlaygroundById: (id) => dispatch(actions.getPlaygroundById(id))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundReviewRoom);