import React, { Component } from 'react';

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";
import { connect } from 'react-redux';

class CodeEditor extends Component {
    state = {  }
    render() { 
        
        const { codeEditorContent, editorConfig} = this.props;
        let {language} = this.props;
        if(!language){
            language = "plain_text";
        }

        return ( <div>
            <AceEditor
                showPrintMargin={false}
                mode={language.toLowerCase()}
                theme={editorConfig.theme}
                readOnly={true}
                width="100%"
                height="90vh"
                name="review-code-editor"
                value={codeEditorContent}
                fontSize={parseInt(editorConfig.fontSize)}
            />
        </div> );
    }
}

const mapStateToProps = (state) => {
    return {
        editorConfig:state.playgroundSession.editorConfig
    }
}

export default connect(mapStateToProps,null)(CodeEditor);