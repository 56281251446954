import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PlaygroundMenu from './playgroundMenu';

//react-icons & ui-components
import { FiEdit, FiTrash2, FiUser, FiCode } from 'react-icons/fi';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

class PlaygroundTable extends Component {

    constructor(props) {
        super(props)
        this.menuRef = React.createRef();
    }
    
    createPlaygroundUpdateLink = (playground) => {

        const { id, title, candidate } = playground;

        return `/dashboard/playgrounds/update?id=${id}&title=${title}&candidateId=${candidate.id}&candidateEmail=${candidate.email}`;
    }

    getStatusClass = (status) => {

        let badgeClass = "status-badge ";
        switch (status) {
            case "ACTIVE":
                return badgeClass + "active";
            case "CREATED":
                return badgeClass + "created"
            default:
                return badgeClass + "ended"

        }
    }

    changeLetterCapitalization = (string) => {
        return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
    }

    render() {
        return (<div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Candidate</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Language</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.playgrounds.map(playground => {
                            return (
                                <TableRow key={playground.id}>
                                    <TableCell>
                                        <p className="playground-text">{playground.title}</p>
                                    </TableCell>
                                    <TableCell>
                                        <FiUser className="playground-icon" />
                                        {playground.candidate.firstName} {playground.candidate.lastName}
                                    </TableCell>
                                    <TableCell>
                                        <p className={this.getStatusClass(playground.playgroundStatus)}>
                                            {playground.playgroundStatus}
                                        </p>
                                    </TableCell>
                                    <TableCell>
                                        <FiCode className="playground-icon" />
                                        {playground.language ?
                                            this.changeLetterCapitalization(playground.language.name) : "None"}
                                    </TableCell>
                                    <TableCell align="right">
                                        {this.props.isOwned ?
                                            <div>
                                                <Tooltip title="Update a playground" arrow>
                                                    <Link to={this.createPlaygroundUpdateLink(playground)}>
                                                        <IconButton>
                                                            <FiEdit size="20px" />
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                                <Tooltip title="Delete a playground" arrow>
                                                    <IconButton onClick={() => this.props.onDelete(playground)}>
                                                        <FiTrash2 size="20px" />
                                                    </IconButton>
                                                </Tooltip>
                                                <PlaygroundMenu {...playground} isOwned={this.props.isOwned} />
                                            </div>
                                            : <PlaygroundMenu {...playground} isOwned={this.props.isOwned}></PlaygroundMenu>}
                                    </TableCell>
                                </TableRow>);
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {this.props.playgrounds.length === 0 &&
                <div className="p-3">
                    <Alert severity="info">No playgrounds available</Alert>
                </div>}

        </div>);
    }
}
export default PlaygroundTable;
