import * as actionTypes from '../actionTypes/languages';
import * as routes from '../../service/routes';
import { httpSecured } from '../../service/axios';


export const getAllLanguages = () => {
    return async (dispatch) => {
        dispatch(beginRequest());
        try {
            const response = await httpSecured.get(routes.LANGUAGES);
            dispatch(allLanguagesFetched(response.data));
        } catch (ex) {
            handleError(dispatch,ex);
        }
    }
}

const allLanguagesFetched = (payload) => {
    return {
        type: actionTypes.LANGUAGES_FETCHED_SUCCESSFULLY,
        payload
    }
}

const failedLanguageRequest = (payload) => {
    return {
        type: actionTypes.LANGUAGES_REQUEST_FAILED,
        payload
    }
}

const beginRequest = () => {
    return {
        type: actionTypes.BEGIN_LANGUAGE_REQUEST
    }
}

const handleError = (dispatch,ex) => {
    if(ex.response){
        dispatch(failedLanguageRequest(ex.response.data.detail))
    }else{
        dispatch(failedLanguageRequest("Unexpected Error Ocurred"));
    }
}