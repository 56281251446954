import React, { Component } from 'react';
import QuestionCard from './questionCard';
import { connect } from 'react-redux';
import * as actions from '../../infrastructure/store/actions/questions';
import QuestionCardSelectable from './questionCardSelectable';
import { Alert } from '@material-ui/lab';
import ConfirmationDialog from '../Commons/ConfirmationDialog';
import {FiXCircle,FiEdit3 } from 'react-icons/fi';

class QuestionContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            codeEditorContent: "",
            isConfirmDialogOpen: false,
            questionId:0
        };
    }

    componentDidMount = () => {
        const { id } = this.props.currentUser;
        this.props.getAllQuestions(id);
    }

    closeConfirmDialog = () => {
        this.setState({isConfirmDialogOpen:false})
    }

    handleContent = (value) => {
        this.setState({ codeEditorContent: value });
    }

    openConfigDialog = (questionId) =>{
        this.setState({
            isConfirmDialogOpen:true,
            questionId
        })
    }

    deleteQuestion = () => {
        const {questionId} = this.state;
        this.props.deleteQuestion(questionId);
        this.setState({
            isConfirmDialogOpen:false
        })
    }
    render() {
        const questions = this.props.questions.length > 0 ?
            this.props.questions.map(question => {
                return this.props.selectable ? <QuestionCardSelectable
                    {...question}
                    key={question.id}
                    selectable={this.props.selectable}
                    onSelected={this.props.onSelected}
                    onDeselected={this.props.onDeselected}
                ></QuestionCardSelectable>
                    : <QuestionCard key={question.id} {...question} nav={
                        <div style={{float:"right"}}>
                            <FiXCircle onClick={() => this.openConfigDialog(question.id)}  style={{margin:5,fontSize:20,cursor:"pointer",color:"grey"}}></FiXCircle>
                        </div>
                    }></QuestionCard>
            }) : <div>
                <Alert severity="info">No Questions Found</Alert>
            </div>

        return (
            <div className="code-editor-snippter">
                {questions}
                <ConfirmationDialog
                    title="Delete a Question"
                    content="Your are about to delete a question. This action cannot be reverted."
                    isOpen={this.state.isConfirmDialogOpen}
                    onConfirm={this.deleteQuestion}
                    onCancel={this.closeConfirmDialog}
                    ></ConfirmationDialog>
            </div>);
    }
}

const mapStateToProps = (state) => {
    return {
        questions: state.question.questions,
        currentUser: state.auth.currentUser.userInfo
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        createQuestion: (title, content, languageId) => dispatch(actions.createQuestion(title, content, languageId)),
        getAllQuestions: (userId) => dispatch(actions.getAllQuestions(userId)),
        deleteQuestion: (questionId) => dispatch(actions.deleteQuestion(questionId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionContainer);